import React, { useEffect, useState, useContext, useMemo } from "react";
import {
  Container,
  ListGroup,
  Table,
  Button,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Card,
  Pagination,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import firebaseService from "../FirebaseService";
import { DateTime } from "luxon";
import { useAuth } from "../context/AuthContext";
import { NotificationContext } from "../context/NotificationContext";
import useWindowWidth from "../hooks/useWindowWidth";
import { formatNumberWithCommas, removeCommas } from "../utils/numberUtils";

import "../styles/ModalStyles.css";
import "../styles/TableStyles.css";
import "../styles/CardStyles.css";

import {
  parseDateFromInput,
  formatDateForDisplay,
  fromFirestoreTimestamp,
  isValidDate,
} from "../utils/dateUtils";

function Equipment() {
  // Use context functions
  const { showSuccess, showModalError } = useContext(NotificationContext);

  const width = useWindowWidth();
  const isMobile = width <= 576; // Adjust breakpoint if needed

  const { currentUser } = useAuth();
  const [users, setUsers] = useState([]);
  const [assignedOperators, setAssignedOperators] = useState([]);
  const [operatorSearchTerm, setOperatorSearchTerm] = useState("");
  const [operatorSearchResults, setOperatorSearchResults] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [filteredEquipment, setFilteredEquipment] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [showAddEquipmentModal, setShowAddEquipmentModal] = useState(false);
  const [showEditEquipmentModal, setShowEditEquipmentModal] = useState(false);
  const [showDeleteEquipmentModal, setShowDeleteEquipmentModal] =
    useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCompanyFilter, setSelectedCompanyFilter] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [newEquipmentData, setNewEquipmentData] = useState({
    truckNumber: "",
    engineMakeModel: "",
    estMPG: "",
    filterSerialNumber: "",
    calcDateNextFilterChange: "",
    companyId: "",
    initialMileage: "",
    initialHours: "",
    fuelFilterTypeId: "",
    oilFilterTypeId: "",
  });
  const [currentEquipment, setCurrentEquipment] = useState(null);
  const [equipmentToDelete, setEquipmentToDelete] = useState(null);

  // Fuel Logs
  const [showAddFuelLogModal, setShowAddFuelLogModal] = useState(false);
  const [showEditFuelLogsModal, setShowEditFuelLogsModal] = useState(false);
  const [fuelLogData, setFuelLogData] = useState({
    engineHours: "",
    currentMileage: "",
    gallons: "",
    defGallons: "",
    logDate: "",
  });
  const [fuelLogs, setFuelLogs] = useState([]);
  const [currentEquipmentForFuelLog, setCurrentEquipmentForFuelLog] =
    useState(null);
  const [editingFuelLogId, setEditingFuelLogId] = useState(null);

  // Average Fuel MPG (Global, across filteredEquipment)
  const [averageMPG, setAverageMPG] = useState(null);
  const [mpgChange, setMPGChange] = useState(null);
  const [changeDirection, setChangeDirection] = useState(null);

  // Filter Logs
  const [showAddServiceLogModal, setShowAddServiceLogModal] = useState(false);
  const [showEditServiceLogsModal, setShowEditServiceLogsModal] =
    useState(false);
  const [serviceLogData, setServiceLogData] = useState({
    engineHours: "",
    currentMileage: "",
    filterChangeDate: "",
  });
  const [serviceLogs, setServiceLogs] = useState([]);
  const [currentEquipmentForServiceLog, setCurrentEquipmentForServiceLog] =
    useState(null);
  const [editingServiceLogId, setEditingServiceLogId] = useState(null);
  const [filterTypes, setFilterTypes] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const isAdmin = currentUser?.role === "admin";
  const isOwner = currentUser?.role === "owner";
  const isMechanic = currentUser?.role === "mechanic";

  const MONTHS_OF_MPG_COMPARISON = 1;

  const [fuelLogsMap, setFuelLogsMap] = useState({}); // { equipmentId: [fuelLogs] }
  const [mpgMap, setMpgMap] = useState({}); // { equipmentId: computedMPG }

  useEffect(() => {
    async function fetchOverall30DayMPG() {
      // logs for all filtered equipment
      const eqIds = filteredEquipment.map((eq) => eq.id);
      if (eqIds.length === 0) {
        setAverageMPG(null);
        setMPGChange(null);
        setChangeDirection(null);
        return;
      }

      const allLogs = await firebaseService.getFuelLogsByEquipmentIds(eqIds);

      // Sort logs ascending
      const sortedLogs = [...allLogs].sort(
        (a, b) => a.logDate.toMillis() - b.logDate.toMillis()
      );

      // Partition last 30 vs. previous 30
      const now = new Date();
      const last30Start = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
      const prev30Start = new Date(now.getTime() - 60 * 24 * 60 * 60 * 1000);

      const prev30Logs = sortedLogs.filter((l) => {
        const d = l.logDate.toDate();
        return d >= prev30Start && d < last30Start;
      });
      const last30Logs = sortedLogs.filter(
        (l) => l.logDate.toDate() >= last30Start
      );

      const mpgPrev = firebaseService.calculateFillUpMPGForLogSet(prev30Logs);
      const mpgLast = firebaseService.calculateFillUpMPGForLogSet(last30Logs);

      setAverageMPG(mpgLast || null);
      if (mpgLast && mpgPrev) {
        const change = mpgLast - mpgPrev;
        setMPGChange(Math.abs(change));
        setChangeDirection(change > 0 ? "↑" : "↓");
      } else {
        setMPGChange(null);
        setChangeDirection(null);
      }
    }

    fetchOverall30DayMPG();
  }, [filteredEquipment]);

  useEffect(() => {
    async function fetchData() {
      try {
        const [equipmentData, companiesData, usersData, filterTypesData] =
          await Promise.all([
            firebaseService.getEquipment(currentUser),
            isAdmin
              ? firebaseService.getAllCompanies()
              : firebaseService.getCompanies(currentUser),
            isAdmin
              ? firebaseService.getAllUsers()
              : firebaseService.getUsersByCompanyId(currentUser.companyId),
            firebaseService.getFilterTypes(),
          ]);

        // Fetch log counts for each equipment
        const equipmentWithLogCounts = await Promise.all(
          equipmentData.map(async (equipment) => {
            const [fuelLogsCount, serviceLogsCount] = await Promise.all([
              firebaseService.getFuelLogsCount(equipment.id),
              firebaseService.getServiceLogsCount(equipment.id),
            ]);
            return {
              ...equipment,
              fuelLogsCount,
              serviceLogsCount,
            };
          })
        );

        setEquipmentList(equipmentWithLogCounts);
        setCompanies(companiesData);
        setUsers(usersData);
        setFilterTypes(filterTypesData);
      } catch (error) {
        showModalError(error.message);
      }
    }
    fetchData();
  }, [isAdmin, currentUser, showModalError]);

  // Create a lookup object for filterTypes
  const filterTypeLookup = useMemo(() => {
    const lookup = {};
    filterTypes.forEach((filterType) => {
      lookup[filterType.id] = filterType;
    });
    return lookup;
  }, [filterTypes]);

  useEffect(() => {
    let filtered = equipmentList;

    // Filter by company
    if (selectedCompanyFilter) {
      filtered = filtered.filter(
        (item) => item.companyId === selectedCompanyFilter
      );
    }

    // Filter by search term (truckNumber or filterSerialNumber)
    if (searchTerm) {
      filtered = filtered.filter(
        (item) =>
          (item.truckNumber &&
            item.truckNumber
              .toLowerCase()
              .includes(searchTerm.toLowerCase())) ||
          (item.filterSerialNumber &&
            item.filterSerialNumber
              .toLowerCase()
              .includes(searchTerm.toLowerCase()))
      );
    }

    // Sorting
    if (sortField) {
      filtered.sort((a, b) => {
        let aValue = a[sortField] || "";
        let bValue = b[sortField] || "";

        if (
          sortField === "calcDateNextFilterChange" ||
          sortField === "estNextFilterChangeDate"
        ) {
          aValue = aValue ? aValue.toDate() : new Date(0);
          bValue = bValue ? bValue.toDate() : new Date(0);
        }

        if (sortField === "initialMileage") {
          aValue = parseInt(aValue, 10) || 0;
          bValue = parseInt(bValue, 10) || 0;
        }

        if (sortField === "initialHours") {
          aValue = parseInt(aValue, 10) || 0;
          bValue = parseInt(bValue, 10) || 0;
        }

        if (sortField === "fuelFilterTypeId") {
          aValue = filterTypeLookup[aValue]?.name.toLowerCase() || "";
          bValue = filterTypeLookup[bValue]?.name.toLowerCase() || "";
        } else {
          if (typeof aValue === "string") aValue = aValue.toLowerCase();
          if (typeof bValue === "string") bValue = bValue.toLowerCase();
        }

        if (sortDirection === "asc") return aValue > bValue ? 1 : -1;
        else return aValue < bValue ? 1 : -1;
      });
    }

    setFilteredEquipment(filtered);

    // Reset the current page to 1
    setCurrentPage(1);
  }, [
    searchTerm,
    equipmentList,
    selectedCompanyFilter,
    sortField,
    sortDirection,
    filterTypeLookup,
  ]);

  // Fetch all fuel logs for displayed equipment (lazy load)
  useEffect(() => {
    async function fetchAllFuelLogsForEquipment() {
      if (filteredEquipment.length === 0) {
        setFuelLogsMap({});
        setMpgMap({});
        return;
      }

      const equipmentIds = filteredEquipment.map((eq) => eq.id);
      const allLogs = await firebaseService.getFuelLogsByEquipmentIds(
        equipmentIds
      );

      // Group logs by equipmentId
      const grouped = {};
      allLogs.forEach((log) => {
        if (!grouped[log.equipmentId]) grouped[log.equipmentId] = [];
        grouped[log.equipmentId].push(log);
      });

      setFuelLogsMap(grouped);
    }
    fetchAllFuelLogsForEquipment();
  }, [filteredEquipment]);

  useEffect(() => {
    async function computeAllEquipmentMPG() {
      const newMpgMap = {};
      for (const eq of filteredEquipment) {
        const eqLogs = fuelLogsMap[eq.id] || [];
        newMpgMap[eq.id] = firebaseService.getLast30DayMPGForEquipment(
          eq,
          eqLogs
        );
      }
      setMpgMap(newMpgMap);
    }

    if (filteredEquipment.length > 0) {
      computeAllEquipmentMPG();
    } else {
      setMpgMap({});
    }
  }, [filteredEquipment, fuelLogsMap]);

  async function recalculateAfterInitialMileageChange(equipmentId) {
    try {
      await firebaseService.recalculateFuelLogsAfterInitialMileageChange(
        equipmentId
      );
      // Refetch logs and recalc MPG for that equipment
      const eqLogs = await firebaseService.getFuelLogs(equipmentId);
      setFuelLogsMap((prev) => ({ ...prev, [equipmentId]: eqLogs }));

      const eq =
        filteredEquipment.find((e) => e.id === equipmentId) ||
        equipmentList.find((e) => e.id === equipmentId);
      if (eq) {
        const newMPG = firebaseService.calculateFuelStats(eq, eqLogs);
        // const newStats = firebaseService.calculateFuelStats(eq, eqLogs);
        // const newMPG = newStats.avgMPG30Days || newStats.avgMPGAllTime || 0;
        setMpgMap((prev) => ({ ...prev, [equipmentId]: newMPG }));
      }
    } catch (error) {
      showModalError(
        `Error recalculating logs after initialMileage change: ${error.message}`
      );
    }
  }

  // Fuel Log Handlers
  function handleAddFuelLog(equipment) {
    setCurrentEquipmentForFuelLog(equipment);
    const today = DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm");
    setFuelLogData({
      engineHours: "",
      currentMileage: "",
      gallons: "",
      logDate: today,
    });
    setEditingFuelLogId(null);
    setShowAddFuelLogModal(true);
  }

  function handleAddFuelLogClose() {
    setShowAddFuelLogModal(false);
  }

  function handleEditFuelLogs(equipment) {
    setCurrentEquipmentForFuelLog(equipment);
    fetchFuelLogs(equipment.id);
    setShowEditFuelLogsModal(true);
  }

  function handleEditFuelLogsClose() {
    setShowEditFuelLogsModal(false);
    setFuelLogs([]);
    setCurrentEquipmentForFuelLog(null);
  }

  async function fetchFuelLogs(equipmentId) {
    try {
      const logs = await firebaseService.getFuelLogs(equipmentId);
      setFuelLogs(logs);
    } catch (error) {
      showModalError(error.message);
    }
  }

  // Handle Input Change
  function handleFuelLogChange(event) {
    const { name, value } = event.target;
    if (name === "currentMileage" || name === "engineHours") {
      const numericValue = removeCommas(value);
      if (numericValue === "") {
        setFuelLogData((prevData) => ({
          ...prevData,
          [name]: "",
        }));
      } else if (!isNaN(numericValue)) {
        const formattedValue = formatNumberWithCommas(numericValue);
        setFuelLogData((prevData) => ({
          ...prevData,
          [name]: formattedValue,
        }));
      }
    } else {
      setFuelLogData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  }

  async function handleFuelLogSubmit(event) {
    try {
      event.preventDefault();
      const { engineHours, currentMileage, gallons, defGallons, logDate } =
        fuelLogData;

      if (!currentEquipmentForFuelLog) {
        showModalError("No equipment selected for fuel log.");
        return;
      }

      // Validate required fields
      if (!engineHours || !currentMileage || !logDate) {
        showModalError("Please fill in all required fields.");
        return;
      }

      // Validate date format
      if (!isValidDate(logDate)) {
        showModalError("Please enter a valid date for Fuel Log Date.");
        return;
      }

      const parsedLogDate = parseDateFromInput(logDate); // Luxon DateTime
      const parsedJSLogDate = parsedLogDate.toJSDate(); // Convert to JS Date
      const numericEngineHours = removeCommas(engineHours);
      const numericCurrentMileage = removeCommas(currentMileage);
      const numericGallons = removeCommas(gallons);
      const numericDefGallons = removeCommas(defGallons);

      const logDataToSubmit = {
        equipmentId: currentEquipmentForFuelLog.id,
        userId: currentUser.id,
        engineHours: numericEngineHours,
        currentMileage: numericCurrentMileage,
        gallons: numericGallons,
        defGallons: numericDefGallons,
        logDate: parsedJSLogDate,
      };

      try {
        if (editingFuelLogId) {
          // Update existing log
          await firebaseService.updateFuelLogWithMPG(
            editingFuelLogId,
            logDataToSubmit
          );
          showSuccess("Fuel log updated successfully.");
        } else {
          // Add new log
          await firebaseService.addFuelLogWithMPG(logDataToSubmit);
          showSuccess("Fuel log added successfully.");
        }

        const equipmentId = currentEquipmentForFuelLog.id;

        // Recalculate filter life remaining if this log is the latest
        await firebaseService.recalculateFilterLifeRemaining(equipmentId);

        handleAddFuelLogClose();

        // Refresh logs and counts
        fetchFuelLogs(equipmentId);
        updateEquipmentLogCounts(equipmentId);

        // Also update the fuelLogsMap and mpgMap since logs changed
        const eqLogs = await firebaseService.getFuelLogs(equipmentId);
        setFuelLogsMap((prev) => ({ ...prev, [equipmentId]: eqLogs }));
        const eq =
          filteredEquipment.find((e) => e.id === equipmentId) ||
          equipmentList.find((e) => e.id === equipmentId);
        if (eq) {
          const newStats = firebaseService.calculateFuelStats(eq, eqLogs);
          const newMPG = newStats.avgMPG30Days || newStats.avgMPGAllTime || 0;
          setMpgMap((prev) => ({ ...prev, [equipmentId]: newMPG }));
        }
      } catch (error) {
        console.log(error);
        showModalError(error.message);
      }
    } catch (error) {
      console.log(error);
      showModalError(error.message);
    }
  }

  async function handleDeleteFuelLog(log) {
    if (window.confirm("Are you sure you want to delete this fuel log?")) {
      try {
        await firebaseService.deleteFuelLogWithMPG(log.id);
        showSuccess("Fuel log deleted successfully.");

        const equipmentId = currentEquipmentForFuelLog.id;
        // Refresh logs and counts
        fetchFuelLogs(equipmentId);
        updateEquipmentLogCounts(equipmentId);

        // Update cached logs and MPG
        const eqLogs = await firebaseService.getFuelLogs(equipmentId);
        setFuelLogsMap((prev) => ({ ...prev, [equipmentId]: eqLogs }));
        const eq =
          filteredEquipment.find((e) => e.id === equipmentId) ||
          equipmentList.find((e) => e.id === equipmentId);
        if (eq) {
          const newStats = firebaseService.calculateFuelStats(eq, eqLogs);
          const newMPG = newStats.avgMPG30Days || newStats.avgMPGAllTime || 0;
          setMpgMap((prev) => ({ ...prev, [equipmentId]: newMPG }));
        }

        // Recalculate filter life remaining if this log is the latest
        await firebaseService.recalculateFilterLifeRemaining(
          currentEquipmentForFuelLog.id
        );
      } catch (error) {
        showModalError(error.message);
      }
    }
  }

  async function updateEquipmentLogCounts(equipmentId) {
    try {
      const [fuelLogsCount, serviceLogsCount] = await Promise.all([
        firebaseService.getFuelLogsCount(equipmentId),
        firebaseService.getServiceLogsCount(equipmentId),
      ]);

      setEquipmentList((prevList) =>
        prevList.map((equipment) =>
          equipment.id === equipmentId
            ? { ...equipment, fuelLogsCount, serviceLogsCount }
            : equipment
        )
      );
    } catch (error) {
      showModalError(error.message);
    }
  }

  function handleEditFuelLog(log) {
    setFuelLogData({
      engineHours: log.engineHours,
      currentMileage: log.currentMileage,
      gallons: log.gallons,
      defGallons: log.defGallons,
      logDate: log.logDate
        ? fromFirestoreTimestamp(log.logDate).toFormat("yyyy-MM-dd'T'HH:mm")
        : "",
    });
    setEditingFuelLogId(log.id);
    setShowAddFuelLogModal(true);
  }

  // Function to handle opening the Add Filter Log modal
  function handleAddServiceLog(equipment) {
    setCurrentEquipmentForServiceLog(equipment);
    const today = DateTime.local().toFormat("yyyy-MM-dd'T'HH:mm");
    setServiceLogData({
      engineHours: "",
      currentMileage: "",
      filterChangeDate: today,
    });
    setEditingServiceLogId(null);
    setShowAddServiceLogModal(true);
  }

  // Function to handle closing the Add Filter Log modal
  function handleAddServiceLogClose() {
    setShowAddServiceLogModal(false);
  }

  // Function to handle opening the Edit Filter Logs modal
  function handleEditServiceLogs(equipment) {
    setCurrentEquipmentForServiceLog(equipment);
    fetchServiceLogs(equipment.id);
    setShowEditServiceLogsModal(true);
  }

  // Function to handle closing the Edit Filter Logs modal
  function handleEditServiceLogsClose() {
    setShowEditServiceLogsModal(false);
    setServiceLogs([]);
    setCurrentEquipmentForServiceLog(null);
  }

  // Function to fetch filter logs for a specific equipment
  async function fetchServiceLogs(equipmentId) {
    try {
      const logs = await firebaseService.getServiceLogs(equipmentId);
      setServiceLogs(logs);
    } catch (error) {
      showModalError(error.message);
    }
  }

  // Function to handle input changes in the Filter Log form
  function handleServiceLogChange(event) {
    const { name, value } = event.target;
    if (name === "currentMileage" || name === "engineHours") {
      const numericValue = removeCommas(value);
      if (numericValue === "") {
        setServiceLogData((prevData) => ({
          ...prevData,
          [name]: "",
        }));
      } else if (!isNaN(numericValue)) {
        const formattedValue = formatNumberWithCommas(numericValue);
        setServiceLogData((prevData) => ({
          ...prevData,
          [name]: formattedValue,
        }));
      }
    } else {
      setServiceLogData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  }

  // Function to handle the submission of the Filter Log form
  async function handleServiceLogSubmit(event) {
    event.preventDefault();
    const { engineHours, currentMileage, filterChangeDate } = serviceLogData;

    if (!currentEquipmentForServiceLog) {
      showModalError("No equipment selected for filter log.");
      return;
    }

    // Validate required fields
    if (!engineHours || !currentMileage || !filterChangeDate) {
      showModalError("Please fill in all required fields.");
      return;
    }

    // Validate date format
    if (!isValidDate(filterChangeDate)) {
      showModalError("Please enter a valid date for Filter Change Date.");
      return;
    }

    const parsedFilterChangeDate = parseDateFromInput(filterChangeDate);
    const numericEngineHours = removeCommas(engineHours);
    const numericCurrentMileage = removeCommas(currentMileage);

    const logDataToSubmit = {
      equipmentId: currentEquipmentForServiceLog.id,
      userId: currentUser.id,
      engineHours: numericEngineHours,
      currentMileage: numericCurrentMileage,
      filterChangeDate: parsedFilterChangeDate,
    };

    try {
      if (editingServiceLogId) {
        // Update existing log
        await firebaseService.updateServiceLog(
          editingServiceLogId,
          logDataToSubmit
        );
        showSuccess("Filter log updated successfully.");
      } else {
        // Add new log
        await firebaseService.addServiceLog(logDataToSubmit);
        showSuccess("Filter log added successfully.");
      }

      const equipmentId = currentEquipmentForServiceLog.id;

      // This represents a new filter install (fuel filter for now).
      // If your logic also needs to consider oil filters in service logs,
      // you'd expand that here. Currently, the code tracks whichever
      // filter was changed, but we haven't introduced separate service logs
      // for oil filters in this snippet.

      const eq =
        filteredEquipment.find((e) => e.id === equipmentId) ||
        equipmentList.find((e) => e.id === equipmentId);
      if (eq) {
        let filterTypeData = null;
        if (eq.fuelFilterTypeId) {
          filterTypeData = filterTypes.find(
            (ft) => ft.id === eq.fuelFilterTypeId
          );
        }

        const lifeFields = firebaseService.getFilterLifeFields(filterTypeData);

        const metricsData = {
          filterChangedDate: logDataToSubmit.filterChangeDate.toJSDate
            ? logDataToSubmit.filterChangeDate.toJSDate()
            : logDataToSubmit.filterChangeDate,
          engineHours: logDataToSubmit.engineHours,
          mileage: logDataToSubmit.currentMileage,
          ...lifeFields,
        };

        // Upsert metrics
        await firebaseService.upsertEquipmentMetrics(equipmentId, metricsData);
      }

      // Recalculate filter life remaining if this log is the latest
      await firebaseService.recalculateFilterLifeRemaining(equipmentId);

      handleAddServiceLogClose();
      // Refresh logs and counts
      fetchServiceLogs(equipmentId);
      updateEquipmentLogCounts(equipmentId);

      // Reset currentEquipmentForServiceLog after processing
      // setCurrentEquipmentForServiceLog(null);
    } catch (error) {
      showModalError(error.message);
    }
  }

  // Function to handle editing a filter log
  function handleEditServiceLog(log) {
    setServiceLogData({
      engineHours: log.engineHours,
      currentMileage: log.currentMileage,
      filterChangeDate: log.filterChangeDate
        ? fromFirestoreTimestamp(log.filterChangeDate).toFormat(
            "yyyy-MM-dd'T'HH:mm"
          )
        : "",
    });
    setEditingServiceLogId(log.id);
    setShowAddServiceLogModal(true);
  }

  // Function to handle deleting a filter log
  async function handleDeleteServiceLog(log) {
    if (window.confirm("Are you sure you want to delete this filter log?")) {
      try {
        await firebaseService.deleteServiceLog(log.id);
        showSuccess("Filter log deleted successfully.");
        // Refresh metrics, logs and counts
        await firebaseService.updateMetricWithLatestServiceLogHoursAndMileage(
          currentEquipmentForServiceLog.id
        );
        fetchServiceLogs(currentEquipmentForServiceLog.id);
        updateEquipmentLogCounts(currentEquipmentForServiceLog.id);
      } catch (error) {
        showModalError(error.message);
      }
    }
  }

  // Equipment Handlers
  function handleAddEquipment() {
    setShowAddEquipmentModal(true);
    setNewEquipmentData({
      truckNumber: "",
      engineMakeModel: "",
      estMPG: "",
      filterSerialNumber: "",
      calcDateNextFilterChange: "",
      companyId: "",
      initialMileage: "",
      initialHours: "",
      fuelFilterTypeId: "",
      oilFilterTypeId: "",
    });
  }

  function handleAddEquipmentClose() {
    setShowAddEquipmentModal(false);
    setNewEquipmentData({
      truckNumber: "",
      engineMakeModel: "",
      estMPG: "",
      filterSerialNumber: "",
      calcDateNextFilterChange: "",
      companyId: "",
      initialMileage: "",
      initialHours: "",
      fuelFilterTypeId: "",
      oilFilterTypeId: "",
    });
  }

  async function handleAddEquipmentSubmit(event) {
    event.preventDefault();

    // Check for duplicate Truck Number
    // const duplicate = equipmentList.some(
    //   (item) =>
    //     item.truckNumber &&
    //     newEquipmentData.truckNumber &&
    //     item.truckNumber.toLowerCase() ===
    //       newEquipmentData.truckNumber.toLowerCase() &&
    //     item.companyId &&
    //     newEquipmentData.companyId &&
    //     item.companyId === newEquipmentData.companyId
    // );
    // if (duplicate) {
    //   showModalError(
    //     "An equipment with the same Truck Number for this company already exists. Please use a different Truck Number."
    //   );
    //   return;
    // }

    try {
      const numericInitialMileage = newEquipmentData.initialMileage
        ? removeCommas(newEquipmentData.initialMileage)
        : null;
      const numericEstMPG = newEquipmentData.estMPG
        ? newEquipmentData.estMPG
        : null;

      const initialCalculatedGallonsFuelUsed =
        numericInitialMileage && numericEstMPG && numericEstMPG > 0
          ? numericInitialMileage / numericEstMPG
          : null;

      const equipmentDataToSave = {
        ...newEquipmentData,
        calcDateNextFilterChange: newEquipmentData.calcDateNextFilterChange
          ? parseDateFromInput(newEquipmentData.calcDateNextFilterChange)
          : null,
        initialMileage: numericInitialMileage,
        initialHours: newEquipmentData.initialHours
          ? removeCommas(newEquipmentData.initialHours)
          : null,
        fuelFilterTypeId: newEquipmentData.fuelFilterTypeId || null,
        oilFilterTypeId: newEquipmentData.oilFilterTypeId || null,
        initialCalculatedGallonsFuelUsed,
      };
      // Use equipmentDataToSave when saving to the database
      const equipmentDocRef = await firebaseService.addEquipment(
        equipmentDataToSave
      );

      // Update company's equipment array
      if (newEquipmentData.companyId) {
        await firebaseService.addEquipmentToCompany(
          newEquipmentData.companyId,
          equipmentDocRef.id
        );
      }

      // If no service logs exist yet for this new equipment, create equipmentMetrics
      // Get filterType data if fuelFilterTypeId is set
      let filterTypeData = null;
      if (equipmentDataToSave.fuelFilterTypeId) {
        filterTypeData = filterTypes.find(
          (ft) => ft.id === equipmentDataToSave.fuelFilterTypeId
        );
      }

      const lifeFields = firebaseService.getFilterLifeFields(filterTypeData);

      const metricsData = {
        equipmentId: equipmentDocRef.id,
        filterChangedDate: new Date(), // current date as filter install date
        engineHours: equipmentDataToSave.initialHours || 0,
        mileage: equipmentDataToSave.initialMileage || 0,
        ...lifeFields,
      };

      await firebaseService.upsertEquipmentMetrics(
        equipmentDocRef.id,
        metricsData
      );

      handleAddEquipmentClose();
      // Refresh equipment list
      const equipmentData = await firebaseService.getAllEquipment();
      setEquipmentList(equipmentData);
    } catch (error) {
      showModalError(error.message);
    }
  }

  function handleNewEquipmentChange(event) {
    const { name, value } = event.target;
    if (name === "initialMileage" || name === "initialHours") {
      const numericValue = removeCommas(value);
      if (numericValue === "") {
        setNewEquipmentData((prevData) => ({
          ...prevData,
          [name]: "",
        }));
      } else if (!isNaN(numericValue)) {
        const formattedValue = formatNumberWithCommas(numericValue);
        setNewEquipmentData((prevData) => ({
          ...prevData,
          [name]: formattedValue,
        }));
      }
    } else if (name === "engineMakeModel" || name === "filterSerialNumber") {
      setNewEquipmentData((prevData) => ({
        ...prevData,
        [name]: typeof value === "string" ? value : value,
      }));
    } else {
      setNewEquipmentData((prevData) => ({
        ...prevData,
        [name]: typeof value === "string" ? value.trim() : value,
      }));
    }
  }

  function handleEditEquipment(equipment) {
    setCurrentEquipment(equipment);
    const equipmentData = {
      ...equipment,
      calcDateNextFilterChange: equipment.calcDateNextFilterChange
        ? fromFirestoreTimestamp(equipment.calcDateNextFilterChange).toFormat(
            "yyyy-MM-dd'T'HH:mm"
          )
        : "",
      truckNumber: equipment.truckNumber || "",
      engineMakeModel: equipment.engineMakeModel || "",
      estMPG: equipment.estMPG || "",
      filterSerialNumber: equipment.filterSerialNumber || "",
      companyId: equipment.companyId || "",
      // Format initialMileage and initialHours with commas here
      initialMileage: equipment.initialMileage
        ? formatNumberWithCommas(equipment.initialMileage)
        : "",
      initialHours: equipment.initialHours
        ? formatNumberWithCommas(equipment.initialHours)
        : "",
      fuelFilterTypeId: equipment.fuelFilterTypeId || "",
      oilFilterTypeId: equipment.oilFilterTypeId || "",
    };
    setNewEquipmentData(equipmentData);

    // Ensure assignedOperators is an array
    const operatorsArray = Array.isArray(equipment.assignedOperators)
      ? equipment.assignedOperators
      : [];
    setAssignedOperators(operatorsArray);

    // Clear operator search term and results when opening the modal
    setOperatorSearchTerm("");
    setOperatorSearchResults([]);

    setShowEditEquipmentModal(true);
  }

  function handleEditEquipmentClose() {
    setShowEditEquipmentModal(false);
    setCurrentEquipment(null);
    // Clear operator search term and results when closing the modal
    setOperatorSearchTerm("");
    setOperatorSearchResults([]);
  }

  async function handleEditEquipmentSubmit(event) {
    event.preventDefault();

    // Validate date field
    const field = "calcDateNextFilterChange";
    if (newEquipmentData[field] && !isValidDate(newEquipmentData[field])) {
      showModalError(
        `Invalid date entered for Estimated Next Filter Change Date. Please enter a valid date.`
      );
      return;
    }

    // Check for duplicate Truck Number, excluding current equipment
    const duplicate = equipmentList.some(
      (item) =>
        item.id !== currentEquipment.id &&
        item.truckNumber &&
        newEquipmentData.truckNumber &&
        item.truckNumber.toLowerCase() ===
          newEquipmentData.truckNumber.toLowerCase()
    );
    if (duplicate) {
      showModalError(
        "An equipment with the same Truck Number already exists. Please use a different Truck Number."
      );
      return;
    }

    // Handle company change
    if (currentEquipment.companyId !== newEquipmentData.companyId) {
      // Remove equipment from previous company's equipment array
      if (currentEquipment.companyId) {
        try {
          await firebaseService.removeEquipmentFromCompany(
            currentEquipment.companyId,
            currentEquipment.id
          );
        } catch (error) {
          showModalError(`1 ${error.message}`);
        }
      }
      // Add equipment to new company's equipment array
      if (newEquipmentData.companyId) {
        try {
          await firebaseService.addEquipmentToCompany(
            newEquipmentData.companyId,
            currentEquipment.id
          );
        } catch (error) {
          showModalError(`2 ${error.message}`);
        }
      }
    }

    try {
      const numericInitialMileage = newEquipmentData.initialMileage
        ? removeCommas(newEquipmentData.initialMileage)
        : null;
      const numericEstMPG = newEquipmentData.estMPG
        ? newEquipmentData.estMPG
        : null;

      const initialCalculatedGallonsFuelUsed =
        numericInitialMileage && numericEstMPG && numericEstMPG > 0
          ? numericInitialMileage / numericEstMPG
          : null;

      const equipmentDataToUpdate = {
        ...newEquipmentData,
        calcDateNextFilterChange: newEquipmentData.calcDateNextFilterChange
          ? parseDateFromInput(newEquipmentData.calcDateNextFilterChange)
          : null,
        initialMileage: numericInitialMileage,
        initialHours: newEquipmentData.initialHours
          ? removeCommas(newEquipmentData.initialHours)
          : null,
        assignedOperators,
        fuelFilterTypeId: newEquipmentData.fuelFilterTypeId || null,
        oilFilterTypeId: newEquipmentData.oilFilterTypeId || null,
        initialCalculatedGallonsFuelUsed: initialCalculatedGallonsFuelUsed,
      };
      // Update equipment data including assigned operators

      const oldInitialMileage = currentEquipment.initialMileage || 0;
      const initialMileageChanged = oldInitialMileage !== numericInitialMileage;

      await firebaseService.updateEquipment(
        currentEquipment.id,
        equipmentDataToUpdate
      );

      // Check if no service logs
      const serviceLogCount = await firebaseService.getServiceLogsCount(
        currentEquipment.id
      );
      if (serviceLogCount === 0) {
        // Then this is still like initial filter install scenario if no equipmentMetrics exist
        const existingMetrics =
          await firebaseService.getEquipmentMetricsByEquipmentId(
            currentEquipment.id
          );
        if (!existingMetrics) {
          let filterTypeData = null;
          if (equipmentDataToUpdate.fuelFilterTypeId) {
            filterTypeData = filterTypes.find(
              (ft) => ft.id === equipmentDataToUpdate.fuelFilterTypeId
            );
          }

          const lifeFields =
            firebaseService.getFilterLifeFields(filterTypeData);

          const metricsData = {
            equipmentId: currentEquipment.id,
            filterChangedDate: new Date(),
            engineHours: equipmentDataToUpdate.initialHours || 0,
            mileage: equipmentDataToUpdate.initialMileage || 0,
            ...lifeFields,
          };

          await firebaseService.upsertEquipmentMetrics(
            currentEquipment.id,
            metricsData
          );
        }
      }

      handleEditEquipmentClose();
      // Refresh equipment list
      const equipmentData = await firebaseService.getAllEquipment();
      setEquipmentList(equipmentData);
      showSuccess("Equipment updated successfully.");

      if (initialMileageChanged) {
        await recalculateAfterInitialMileageChange(currentEquipment.id);
      }
    } catch (error) {
      showModalError(`3 ${error.message}`);
    }
  }

  function handleEquipmentChange(event) {
    const { name, value } = event.target;
    if (name === "initialMileage" || name === "initialHours") {
      const numericValue = removeCommas(value);
      if (numericValue === "") {
        setNewEquipmentData((prevData) => ({
          ...prevData,
          [name]: "",
        }));
      } else if (!isNaN(numericValue)) {
        const formattedValue = formatNumberWithCommas(numericValue);
        setNewEquipmentData((prevData) => ({
          ...prevData,
          [name]: formattedValue,
        }));
      }
    } else if (name === "engineMakeModel" || name === "filterSerialNumber") {
      setNewEquipmentData((prevData) => ({
        ...prevData,
        [name]: typeof value === "string" ? value : value,
      }));
    } else {
      setNewEquipmentData((prevData) => ({
        ...prevData,
        [name]: typeof value === "string" ? value.trim() : value,
      }));
    }
  }

  function handleDeleteEquipment(equipment) {
    setEquipmentToDelete(equipment);
    setShowDeleteEquipmentModal(true);
  }

  function handleDeleteEquipmentClose() {
    setShowDeleteEquipmentModal(false);
    setEquipmentToDelete(null);
  }

  async function handleConfirmDeleteEquipment() {
    try {
      if (equipmentToDelete) {
        // Remove equipment from company's equipment array
        if (equipmentToDelete.companyId) {
          await firebaseService.removeEquipmentFromCompany(
            equipmentToDelete.companyId,
            equipmentToDelete.id
          );
        }

        await firebaseService.deleteEquipment(equipmentToDelete.id);

        setShowDeleteEquipmentModal(false);
        setEquipmentToDelete(null);
        // Refresh equipment list
        const equipmentData = await firebaseService.getAllEquipment();
        setEquipmentList(equipmentData);
      }
    } catch (error) {
      showModalError(error.message);
    }
  }

  async function handleOperatorSearch() {
    try {
      if (operatorSearchTerm.trim() === "") {
        setOperatorSearchResults([]);
        return;
      }
      const results = users.filter(
        (user) =>
          (user.displayName &&
            user.displayName
              .toLowerCase()
              .includes(operatorSearchTerm.toLowerCase())) ||
          user.email.toLowerCase().includes(operatorSearchTerm.toLowerCase())
      );
      setOperatorSearchResults(results);
    } catch (error) {
      showModalError(error.message);
    }
  }

  function handleAddOperator(operator) {
    if (
      Array.isArray(assignedOperators) &&
      !assignedOperators.includes(operator.id)
    ) {
      setAssignedOperators([...assignedOperators, operator.id]);
    }
  }

  function handleRemoveOperator(operatorId) {
    setAssignedOperators(assignedOperators.filter((id) => id !== operatorId));
  }

  // Pagination
  const totalPages = Math.ceil(filteredEquipment.length / itemsPerPage);
  const paginatedEquipment = filteredEquipment.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function renderPagination() {
    const items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return <Pagination>{items}</Pagination>;
  }

  function handleSort(field) {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  }

  return (
    <Container className="mt-5 position-relative">
      <div className="table-container">
        <div className="row align-items-center mb-3">
          {/* Left Column: Title and Add Equipment Button */}
          <div
            className="col-sm-4 d-flex flex-column align-items-start"
            style={{ maxWidth: "200px", minWidth: "200px" }}
          >
            <h2>Equipment</h2>
            {(isAdmin || isOwner || isMechanic) && (
              <Button
                variant="primary"
                onClick={handleAddEquipment}
                className="mt-2"
              >
                Add Equipment
              </Button>
            )}
          </div>

          {/* Right Column: Avg. MPG Card */}
          <div className="col-sm-3">
            <Card
              className="avg-mpg-card card-dark p-2"
              style={{ maxWidth: "200px", minWidth: "200px" }}
            >
              <Card.Body>
                <Card.Title>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        Showing average MPG for entire results below. Filtering
                        the list shows average MPG for the listed items.
                      </Tooltip>
                    }
                  >
                    <span>Avg. MPG</span>
                  </OverlayTrigger>
                </Card.Title>
                <Card.Text as="div">
                  {mpgChange !== null && (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip>
                          Change from previous {MONTHS_OF_MPG_COMPARISON}{" "}
                          month(s): {mpgChange.toFixed(2)}
                        </Tooltip>
                      }
                    >
                      <div>
                        <strong>
                          {averageMPG !== null ? averageMPG.toFixed(2) : "N/A"}
                        </strong>
                        <span
                          style={{
                            color: changeDirection === "↑" ? "green" : "red",
                            marginLeft: "5px",
                            cursor: "pointer",
                          }}
                        >
                          {changeDirection}
                        </span>
                      </div>
                    </OverlayTrigger>
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
        {/* Filters and other content */}
        <InputGroup className="mb-3">
          <FormControl
            placeholder="Search by Truck Number or Filter S/N"
            aria-label="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {isAdmin && (
            <Form.Select
              value={selectedCompanyFilter}
              onChange={(e) => setSelectedCompanyFilter(e.target.value)}
              className="ml-2"
            >
              <option value="">All Companies</option>
              {companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </Form.Select>
          )}
        </InputGroup>

        {isMobile ? (
          <div>
            {paginatedEquipment.map((equipment) => {
              const computedMPG = mpgMap[equipment.id];
              return (
                <Card key={equipment.id} className="mb-3 card-dark">
                  <Card.Body>
                    <Card.Title>{equipment.truckNumber}</Card.Title>
                    <Card.Text as="div">
                      {/* Each item is on a new row */}
                      <div className="mb-2">
                        <strong>Engine Make/Model:</strong>{" "}
                        {equipment.engineMakeModel || "N/A"}
                      </div>
                      <div className="mb-2">
                        <strong>Assigned Drivers:</strong>{" "}
                        {equipment.assignedOperators &&
                        equipment.assignedOperators.length > 0
                          ? equipment.assignedOperators
                              .map((operatorId) => {
                                const operator = users.find(
                                  (user) => user.id === operatorId
                                );
                                return operator
                                  ? operator.displayName || operator.email
                                  : operatorId;
                              })
                              .join(", ")
                          : "None"}
                      </div>
                      <div className="mb-2">
                        <strong>Est. MPG:</strong>{" "}
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              {typeof computedMPG === "number"
                                ? `${computedMPG.toFixed(
                                    2
                                  )} is calculated from the last 30 days of recorded fuel logs.`
                                : `${computedMPG} is the initial estimated MPG entered in the equipment. Enter your fuel logs to start calculating last 30 days of MPG.`}
                            </Tooltip>
                          }
                        >
                          <span>
                            {typeof computedMPG === "number"
                              ? computedMPG.toFixed(2)
                              : computedMPG}
                          </span>
                        </OverlayTrigger>
                      </div>
                      {/* Fuel Filter line */}
                      <div className="mb-2">
                        <strong>Fuel Filter Type:</strong>{" "}
                        {filterTypeLookup[equipment.fuelFilterTypeId]?.name ||
                          "N/A"}
                      </div>
                      {/* Oil Filter line */}
                      <div className="mb-2">
                        <strong>Oil Filter Type:</strong>{" "}
                        {filterTypeLookup[equipment.oilFilterTypeId]?.name ||
                          "N/A"}
                      </div>
                      <div className="mb-2">
                        <strong>Est. Next Filter Change Date:</strong>{" "}
                        {equipment.calcDateNextFilterChange
                          ? formatDateForDisplay(
                              fromFirestoreTimestamp(
                                equipment.calcDateNextFilterChange
                              )
                            )
                          : "N/A"}
                      </div>
                      <div className="mb-2">
                        <strong>Company:</strong>{" "}
                        {companies.find((c) => c.id === equipment.companyId)
                          ?.name || "Unassigned"}
                      </div>
                      <div className="mb-2">
                        <strong>Filter S/N:</strong>{" "}
                        {equipment.filterSerialNumber || "N/A"}
                      </div>

                      {/* Fuel Logs */}
                      <div className="mb-2">
                        <strong>Fuel Logs:</strong>{" "}
                        {equipment.fuelLogsCount || 0}
                        <div className="d-flex justify-content-start mt-2">
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => handleAddFuelLog(equipment)}
                            className="me-2"
                          >
                            Add
                          </Button>
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => handleEditFuelLogs(equipment)}
                          >
                            Edit
                          </Button>
                        </div>
                      </div>

                      {/* Filter Logs */}
                      <div className="mb-2">
                        <strong>Filter Logs:</strong>{" "}
                        {equipment.serviceLogsCount || 0}
                        <div className="d-flex justify-content-start mt-2">
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => handleAddServiceLog(equipment)}
                            className="me-2"
                          >
                            Add
                          </Button>
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => handleEditServiceLogs(equipment)}
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                    </Card.Text>
                    {/* Actions */}
                    {(isAdmin || isOwner || isMechanic) && (
                      <div className="d-flex justify-content-center mt-2">
                        <Button
                          variant="warning"
                          size="sm"
                          onClick={() => handleEditEquipment(equipment)}
                          className="me-2"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteEquipment(equipment)}
                        >
                          Delete
                        </Button>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        ) : (
          <>
            <div className="table-responsive">
              <Table striped bordered hover variant="dark" responsive>
                <thead>
                  <tr>
                    <th onClick={() => handleSort("truckNumber")}>
                      Truck Number{" "}
                      {sortField === "truckNumber" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                    <th onClick={() => handleSort("engineMakeModel")}>
                      Engine Make/Model{" "}
                      {sortField === "engineMakeModel" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                    <th onClick={() => handleSort("assignedOperators")}>
                      Assigned Drivers
                    </th>
                    <th onClick={() => handleSort("estMPG")}>
                      Est. MPG{" "}
                      {sortField === "estMPG" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                    {/* Fuel Filter Type */}
                    <th onClick={() => handleSort("fuelFilterTypeId")}>
                      Fuel Filter Type{" "}
                      {sortField === "fuelFilterTypeId" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                    {/* Oil Filter Type column */}
                    <th onClick={() => handleSort("oilFilterTypeId")}>
                      Oil Filter Type{" "}
                      {sortField === "oilFilterTypeId" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                    <th onClick={() => handleSort("calcDateNextFilterChange")}>
                      Est. Next Filter Change Date{" "}
                      {sortField === "calcDateNextFilterChange" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                    <th onClick={() => handleSort("companyId")}>
                      Company{" "}
                      {sortField === "companyId" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                    <th onClick={() => handleSort("filterSerialNumber")}>
                      Filter S/N{" "}
                      {sortField === "filterSerialNumber" &&
                        (sortDirection === "asc" ? "▲" : "▼")}
                    </th>
                    <th>Fuel Logs</th>
                    <th>Filter Logs</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedEquipment.map((equipment) => {
                    const parsedEstMPG = parseFloat(equipment.estMPG);
                    const fallbackMPG = !isNaN(parsedEstMPG)
                      ? parsedEstMPG
                      : null;
                    const computedMPG = mpgMap[equipment.id] ?? fallbackMPG;
                    return (
                      <tr key={equipment.id}>
                        <td>{equipment.truckNumber || "N/A"}</td>
                        <td>{equipment.engineMakeModel || "N/A"}</td>
                        <td
                          className={
                            !(
                              equipment.assignedOperators &&
                              equipment.assignedOperators.length > 0
                            )
                              ? "no-drivers-cell"
                              : ""
                          }
                          style={
                            !(
                              equipment.assignedOperators &&
                              equipment.assignedOperators.length > 0
                            )
                              ? {
                                  "--bs-table-bg": "#241a1a",
                                  "--bs-table-bg-state": "#241a1a",
                                  color: "#fff",
                                }
                              : {}
                          }
                        >
                          {equipment.assignedOperators &&
                          equipment.assignedOperators.length > 0
                            ? equipment.assignedOperators
                                .map((operatorId) => {
                                  const operator = users.find(
                                    (user) => user.id === operatorId
                                  );
                                  return operator
                                    ? operator.displayName || operator.email
                                    : operatorId;
                                })
                                .join(", ")
                            : "None"}
                        </td>
                        <td>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                {typeof computedMPG === "number"
                                  ? `${computedMPG.toFixed(
                                      2
                                    )} is calculated from the last 30 days of recorded fuel logs.`
                                  : `${computedMPG} is the initial estimated MPG entered in the equipment. Enter your fuel logs to start calculating last 30 days of MPG.`}
                              </Tooltip>
                            }
                          >
                            <span>
                              {typeof computedMPG === "number"
                                ? computedMPG.toFixed(2)
                                : computedMPG}
                            </span>
                          </OverlayTrigger>
                        </td>
                        {/* Fuel Filter Type */}
                        <td>
                          {filterTypeLookup[equipment.fuelFilterTypeId]?.name ||
                            "N/A"}
                        </td>
                        {/* Oil Filter Type */}
                        <td>
                          {filterTypeLookup[equipment.oilFilterTypeId]?.name ||
                            "N/A"}
                        </td>
                        <td>
                          {equipment.calcDateNextFilterChange
                            ? formatDateForDisplay(
                                fromFirestoreTimestamp(
                                  equipment.calcDateNextFilterChange
                                )
                              )
                            : "N/A"}
                        </td>
                        <td
                          className={
                            !equipment.companyId ? "no-company-cell" : ""
                          }
                          style={
                            !equipment.companyId
                              ? {
                                  "--bs-table-bg": "#241a1a",
                                  "--bs-table-bg-state": "#241a1a",
                                  color: "#fff",
                                }
                              : {}
                          }
                        >
                          {companies.find((c) => c.id === equipment.companyId)
                            ?.name || "Unassigned"}
                        </td>
                        <td>{equipment.filterSerialNumber || "N/A"}</td>

                        {/* Fuel Logs Cell */}
                        <td>
                          {/* Center the count */}
                          <div className="text-center">
                            {equipment.fuelLogsCount || 0}
                          </div>

                          {/* Buttons side by side */}
                          <div className="d-flex justify-content-center mt-2">
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => handleAddFuelLog(equipment)}
                              className="me-2"
                            >
                              Add
                            </Button>
                            <Button
                              variant="warning"
                              size="sm"
                              onClick={() => handleEditFuelLogs(equipment)}
                            >
                              Edit
                            </Button>
                          </div>
                        </td>

                        {/* Filter Logs Cell */}
                        <td>
                          {/* Center the count */}
                          <div className="text-center">
                            {equipment.serviceLogsCount || 0}
                          </div>

                          {/* Buttons side by side */}
                          <div className="d-flex justify-content-center mt-2">
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => handleAddServiceLog(equipment)}
                              className="me-2"
                            >
                              Add
                            </Button>
                            <Button
                              variant="warning"
                              size="sm"
                              onClick={() => handleEditServiceLogs(equipment)}
                            >
                              Edit
                            </Button>
                          </div>
                        </td>

                        {/* Actions */}
                        <td>
                          {(isAdmin || isOwner || isMechanic) && (
                            <div className="d-flex justify-content-center">
                              <Button
                                variant="warning"
                                size="sm"
                                onClick={() => handleEditEquipment(equipment)}
                                className="me-2"
                              >
                                Edit
                              </Button>
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => handleDeleteEquipment(equipment)}
                              >
                                Delete
                              </Button>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            {renderPagination()}
          </>
        )}
      </div>

      {/* Add Equipment Modal */}
      <Modal show={showAddEquipmentModal} onHide={handleAddEquipmentClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Equipment</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleAddEquipmentSubmit}>
          <Modal.Body>
            {/* Truck Number Field */}
            <Form.Group controlId="formTruckNumber" className="mb-3">
              <Form.Label>Truck Number</Form.Label>
              <Form.Control
                type="text"
                name="truckNumber"
                value={newEquipmentData.truckNumber}
                onChange={handleNewEquipmentChange}
                required
                placeholder="Enter Truck Number"
              />
            </Form.Group>
            {/* Engine Make/Model Field */}
            <Form.Group controlId="formEngineMakeModel" className="mb-3">
              <Form.Label>Engine Make/Model</Form.Label>
              <Form.Control
                type="text"
                name="engineMakeModel"
                value={newEquipmentData.engineMakeModel}
                onChange={handleNewEquipmentChange}
                placeholder="Enter Engine Make/Model"
              />
            </Form.Group>
            {/* Initial Mileage Field */}
            <Form.Group controlId="formInitialMileage" className="mb-3">
              <Form.Label>Initial Mileage</Form.Label>
              <Form.Control
                type="text"
                name="initialMileage"
                value={formatNumberWithCommas(
                  newEquipmentData.initialMileage,
                  2
                )}
                onChange={handleNewEquipmentChange}
                placeholder="Enter Initial Mileage"
              />
            </Form.Group>
            {/* Initial Hours Field */}
            <Form.Group controlId="formInitialHours" className="mb-3">
              <Form.Label>Initial Engine Hours</Form.Label>
              <Form.Control
                type="text"
                name="initialHours"
                value={formatNumberWithCommas(newEquipmentData.initialHours, 2)}
                onChange={handleNewEquipmentChange}
                placeholder="Enter Initial Hours"
              />
            </Form.Group>
            {/* Est. MPG Field */}
            <Form.Group controlId="formEstMPG" className="mb-3">
              <Form.Label>Est. MPG</Form.Label>
              <Form.Control
                type="number"
                name="estMPG"
                value={newEquipmentData.estMPG}
                onChange={handleNewEquipmentChange}
                placeholder="Enter Estimated MPG"
              />
            </Form.Group>
            {/* Fuel Filter Type Dropdown */}
            <Form.Group controlId="formFilterType" className="mb-3">
              <Form.Label>Filter Type</Form.Label>
              <Form.Control
                as="select"
                name="fuelFilterTypeId"
                value={newEquipmentData.fuelFilterTypeId}
                onChange={handleNewEquipmentChange}
              >
                <option value="">(Empty)</option>
                {filterTypes
                  .filter((ft) => ft.type === "fuel")
                  .map((filterType) => (
                    <option key={filterType.id} value={filterType.id}>
                      {filterType.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            {/* Oil Filter Type Dropdown */}
            <Form.Group controlId="formOilFilterType" className="mb-3">
              <Form.Label>Oil Filter Type</Form.Label>
              <Form.Control
                as="select"
                name="oilFilterTypeId"
                value={newEquipmentData.oilFilterTypeId}
                onChange={handleNewEquipmentChange}
              >
                <option value="">(Empty)</option>
                {filterTypes
                  .filter((ft) => ft.type === "oil")
                  .map((filterType) => (
                    <option key={filterType.id} value={filterType.id}>
                      {filterType.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            {/* Est. Next Filter Change Date */}
            <Form.Group
              controlId="formCalcDateNextFilterChange"
              className="mb-3"
            >
              <Form.Label>Est. Next Filter Change Date</Form.Label>
              <Form.Control
                type="datetime-local"
                name="calcDateNextFilterChange"
                value={newEquipmentData.calcDateNextFilterChange}
                onChange={handleNewEquipmentChange}
              />
            </Form.Group>
            {/* Filter Serial Number */}
            <Form.Group controlId="formFilterSerialNumber" className="mb-3">
              <Form.Label>Filter S/N</Form.Label>
              <Form.Control
                type="text"
                name="filterSerialNumber"
                value={newEquipmentData.filterSerialNumber}
                onChange={handleNewEquipmentChange}
                placeholder="Enter Filter Serial Number"
              />
            </Form.Group>
            <Form.Group controlId="formCompanyId" className="mb-3">
              <Form.Label>Company</Form.Label>
              <Form.Control
                as="select"
                name="companyId"
                value={newEquipmentData.companyId}
                onChange={handleNewEquipmentChange}
              >
                <option value="">(Empty)</option>
                {companies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAddEquipmentClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Add Equipment
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Edit Equipment Modal */}
      <Modal show={showEditEquipmentModal} onHide={handleEditEquipmentClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Equipment</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleEditEquipmentSubmit}>
          <Modal.Body>
            {/* Truck Number Field */}
            <Form.Group controlId="editTruckNumber" className="mb-3">
              <Form.Label>Truck Number</Form.Label>
              <Form.Control
                type="text"
                name="truckNumber"
                value={newEquipmentData.truckNumber}
                onChange={handleEquipmentChange}
                required
                placeholder="Enter Truck Number"
              />
            </Form.Group>
            {/* Engine Make/Model Field */}
            <Form.Group controlId="editEngineMakeModel" className="mb-3">
              <Form.Label>Engine Make/Model</Form.Label>
              <Form.Control
                type="text"
                name="engineMakeModel"
                value={newEquipmentData.engineMakeModel}
                onChange={handleEquipmentChange}
                placeholder="Enter Engine Make/Model"
              />
            </Form.Group>
            {/* Initial Mileage Field */}
            <Form.Group controlId="editInitialMileage" className="mb-3">
              <Form.Label>Initial Mileage</Form.Label>
              <Form.Control
                type="text"
                name="initialMileage"
                value={formatNumberWithCommas(
                  newEquipmentData.initialMileage,
                  2
                )}
                onChange={handleEquipmentChange}
                placeholder="Enter Initial Mileage"
              />
            </Form.Group>
            {/* Initial Hours Field */}
            <Form.Group controlId="editInitialHours" className="mb-3">
              <Form.Label>Initial Engine Hours</Form.Label>
              <Form.Control
                type="text"
                name="initialHours"
                value={formatNumberWithCommas(newEquipmentData.initialHours, 2)}
                onChange={handleEquipmentChange}
                placeholder="Enter Initial Hours"
              />
            </Form.Group>
            {/* Est. MPG Field */}
            <Form.Group controlId="editEstMPG" className="mb-3">
              <Form.Label>Est. MPG</Form.Label>
              <Form.Control
                type="number"
                name="estMPG"
                value={newEquipmentData.estMPG}
                onChange={handleEquipmentChange}
                placeholder="Enter Estimated MPG"
              />
            </Form.Group>
            {/* Fuel Filter Type Dropdown */}
            <Form.Group controlId="editFuelFilterType" className="mb-3">
              <Form.Label>Fuel Filter Type</Form.Label>
              <Form.Control
                as="select"
                name="fuelFilterTypeId"
                value={newEquipmentData.fuelFilterTypeId}
                onChange={handleEquipmentChange}
              >
                <option value="">(Empty)</option>
                {filterTypes
                  .filter((ft) => ft.type === "fuel")
                  .map((filterType) => (
                    <option key={filterType.id} value={filterType.id}>
                      {filterType.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            {/* Oil Filter Type Dropdown */}
            <Form.Group controlId="editOilFilterType" className="mb-3">
              <Form.Label>Oil Filter Type</Form.Label>
              <Form.Control
                as="select"
                name="oilFilterTypeId"
                value={newEquipmentData.oilFilterTypeId}
                onChange={handleEquipmentChange}
              >
                <option value="">(Empty)</option>
                {filterTypes
                  .filter((ft) => ft.type === "oil")
                  .map((filterType) => (
                    <option key={filterType.id} value={filterType.id}>
                      {filterType.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            {/* Est. Next Filter Change Date */}
            <Form.Group
              controlId="editCalcDateNextFilterChange"
              className="mb-3"
            >
              <Form.Label>Est. Next Filter Change Date</Form.Label>
              <Form.Control
                type="datetime-local"
                name="calcDateNextFilterChange"
                value={newEquipmentData.calcDateNextFilterChange}
                onChange={handleEquipmentChange}
              />
            </Form.Group>
            {/* Filter Serial Number */}
            <Form.Group controlId="editFilterSerialNumber" className="mb-3">
              <Form.Label>Filter S/N</Form.Label>
              <Form.Control
                type="text"
                name="filterSerialNumber"
                value={newEquipmentData.filterSerialNumber}
                onChange={handleEquipmentChange}
                placeholder="Enter Filter Serial Number"
              />
            </Form.Group>
            <Form.Group controlId="editCompanyId" className="mb-3">
              <Form.Label>Company</Form.Label>
              <Form.Control
                as="select"
                name="companyId"
                value={newEquipmentData.companyId}
                onChange={handleEquipmentChange}
              >
                <option value="">(Empty)</option>
                {companies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {/* Assigned Operators */}
            <Form.Group controlId="editAssignedOperators" className="mb-3">
              <Form.Label>Assigned Drivers</Form.Label>
              <ListGroup variant="flush">
                {assignedOperators.length > 0 ? (
                  assignedOperators.map((operatorId) => {
                    const operator = users.find(
                      (user) => user.id === operatorId
                    );
                    return (
                      <ListGroup.Item key={operatorId}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            {operator
                              ? `${operator.displayName || operator.email} (${
                                  operator.role
                                })`
                              : operatorId}
                          </div>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleRemoveOperator(operatorId)}
                          >
                            Remove
                          </Button>
                        </div>
                      </ListGroup.Item>
                    );
                  })
                ) : (
                  <p className="custom-list-item">No drivers assigned.</p>
                )}
              </ListGroup>
            </Form.Group>

            {/* Operator Search */}
            <Form.Group controlId="operatorSearch" className="mb-3">
              <Form.Label>Add Driver</Form.Label>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Search drivers by name or email"
                  aria-label="Search drivers"
                  value={operatorSearchTerm}
                  onChange={(e) => setOperatorSearchTerm(e.target.value)}
                />
                <Button variant="primary" onClick={handleOperatorSearch}>
                  Search
                </Button>
              </InputGroup>
              {operatorSearchResults.length > 0 && (
                <ListGroup variant="flush">
                  {operatorSearchResults.map((operator) => (
                    <ListGroup.Item key={operator.id}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          {`${operator.displayName || operator.email} (${
                            operator.role
                          })`}
                        </div>
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => handleAddOperator(operator)}
                          disabled={assignedOperators.includes(operator.id)}
                        >
                          Add
                        </Button>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleEditEquipmentClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Delete Equipment Confirmation Modal */}
      <Modal
        show={showDeleteEquipmentModal}
        onHide={handleDeleteEquipmentClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the equipment with Truck Number{" "}
          <strong>{equipmentToDelete?.truckNumber}</strong>? This action cannot
          be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteEquipmentClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDeleteEquipment}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add/Edit Fuel Log Modal */}
      <Modal show={showAddFuelLogModal} onHide={handleAddFuelLogClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingFuelLogId ? "Edit Fuel Log" : "Add Fuel Log"}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleFuelLogSubmit}>
          <Modal.Body>
            {/* Engine Hours */}
            <Form.Group controlId="fuelLogEngineHours" className="mb-3">
              <Form.Label>Engine Hours *</Form.Label>
              <Form.Control
                type="text"
                name="engineHours"
                value={formatNumberWithCommas(fuelLogData.engineHours, 2)}
                onChange={handleFuelLogChange}
                required
                placeholder="Enter Engine Hours"
              />
            </Form.Group>
            {/* Current Mileage */}
            <Form.Group controlId="fuelLogCurrentMileage" className="mb-3">
              <Form.Label>Current Mileage *</Form.Label>
              <Form.Control
                type="text"
                name="currentMileage"
                value={formatNumberWithCommas(fuelLogData.currentMileage, 2)}
                onChange={handleFuelLogChange}
                required
                placeholder="Enter Current Mileage"
              />
            </Form.Group>
            {/* Gallons of Fuel Added */}
            <Form.Group controlId="fuelLogGallons" className="mb-3">
              <Form.Label>Gallons of Fuel Added</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                name="gallons"
                value={fuelLogData.gallons}
                onChange={handleFuelLogChange}
                placeholder="Enter Gallons of Fuel Added"
              />
            </Form.Group>
            {/* Gallons of DEF Added */}
            <Form.Group controlId="fuelLogDefGallons" className="mb-3">
              <Form.Label>Gallons of DEF</Form.Label>
              <Form.Control
                type="number"
                step="0.01"
                name="defGallons"
                value={fuelLogData.defGallons}
                onChange={handleFuelLogChange}
                placeholder="Enter Gallons of DEF"
              />
            </Form.Group>
            {/* Fuel Log Date */}
            <Form.Group controlId="fuelLogDate" className="mb-3">
              <Form.Label>Fuel Log Date *</Form.Label>
              <Form.Control
                type="datetime-local"
                name="logDate"
                value={fuelLogData.logDate}
                onChange={handleFuelLogChange}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAddFuelLogClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {editingFuelLogId ? "Save Changes" : "Add Fuel Log"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Edit Fuel Logs Modal */}
      <Modal show={showEditFuelLogsModal} onHide={handleEditFuelLogsClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Fuel Logs for {currentEquipmentForFuelLog?.truckNumber}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {fuelLogs.map((log) => (
              <ListGroup.Item key={log.id}>
                <div className="d-flex justify-content-between">
                  <div>
                    <strong>Date:</strong>{" "}
                    {log.logDate
                      ? formatDateForDisplay(
                          fromFirestoreTimestamp(log.logDate)
                        )
                      : "N/A"}
                    <br />
                    <strong>Engine Hours:</strong> {log.engineHours}
                    <br />
                    <strong>Current Mileage:</strong>{" "}
                    {log.currentMileage.toLocaleString()}
                    <br />
                    <strong>Gallons:</strong> {log.gallons || "N/A"}
                    <br />
                    <strong>Gallons of DEF:</strong> {log.defGallons || "N/A"}{" "}
                  </div>
                  <div>
                    {(log.userId === currentUser.id || isAdmin) && (
                      <>
                        <Button
                          variant="warning"
                          size="sm"
                          onClick={() => handleEditFuelLog(log)}
                          className="me-2"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteFuelLog(log)}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditFuelLogsClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add/Edit Filter Log Modal */}
      <Modal show={showAddServiceLogModal} onHide={handleAddServiceLogClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingServiceLogId ? "Edit Filter Log" : "Add Filter Log"}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleServiceLogSubmit}>
          <Modal.Body>
            {/* Engine Hours */}
            <Form.Group controlId="serviceLogEngineHours" className="mb-3">
              <Form.Label>Engine Hours *</Form.Label>
              <Form.Control
                type="text"
                name="engineHours"
                value={formatNumberWithCommas(serviceLogData.engineHours, 2)}
                onChange={handleServiceLogChange}
                required
                placeholder="Enter Engine Hours"
              />
            </Form.Group>
            {/* Current Mileage */}
            <Form.Group controlId="serviceLogCurrentMileage" className="mb-3">
              <Form.Label>Current Mileage *</Form.Label>
              <Form.Control
                type="text"
                name="currentMileage"
                value={formatNumberWithCommas(serviceLogData.currentMileage, 2)}
                onChange={handleServiceLogChange}
                required
                placeholder="Enter Current Mileage"
              />
            </Form.Group>
            {/* Date Filter Changed */}
            <Form.Group controlId="serviceLogDate" className="mb-3">
              <Form.Label>Date Filter Changed *</Form.Label>
              <Form.Control
                type="datetime-local"
                name="filterChangeDate"
                value={serviceLogData.filterChangeDate}
                onChange={handleServiceLogChange}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAddServiceLogClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {editingServiceLogId ? "Save Changes" : "Add Filter Log"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Edit Filter Logs Modal */}
      <Modal
        show={showEditServiceLogsModal}
        onHide={handleEditServiceLogsClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Filter Logs for {currentEquipmentForServiceLog?.truckNumber}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {serviceLogs.map((log) => (
              <ListGroup.Item key={log.id}>
                <div className="d-flex justify-content-between">
                  <div>
                    <strong>Date Filter Changed:</strong>{" "}
                    {log.filterChangeDate
                      ? formatDateForDisplay(
                          fromFirestoreTimestamp(log.filterChangeDate)
                        )
                      : "N/A"}
                    <br />
                    <strong>Engine Hours:</strong> {log.engineHours}
                    <br />
                    <strong>Current Mileage:</strong>{" "}
                    {log.currentMileage.toLocaleString()}
                  </div>
                  <div>
                    {(log.userId === currentUser.id || isAdmin) && (
                      <>
                        <Button
                          variant="warning"
                          size="sm"
                          onClick={() => handleEditServiceLog(log)}
                          className="me-2"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeleteServiceLog(log)}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEditServiceLogsClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Equipment;
