export const Roles = {
  ADMIN: "admin",
  OWNER: "owner",
  OPERATOR: "operator",
  MECHANIC: "mechanic",
  BILLING: "billing",
  PENDING: "pending",
  DENIED: "denied",
  BLOCKED: "blocked",
  // Add other roles as needed
};
