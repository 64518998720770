export function formatNumberWithCommas(
  value,
  decimalPlaces = null,
  zeroValue = null
) {
  try {
    if (value === null || value === undefined || value === "") return "";

    // Remove existing commas for processing
    const numericValue = value.toString().replace(/,/g, "");

    // Validate the numeric value based on decimalPlaces
    const isNumeric =
      decimalPlaces !== null
        ? /^-?\d*(\.\d*)?$/.test(numericValue) // Allow decimals if decimalPlaces is specified
        : /^-?\d+$/.test(numericValue); // Only allow whole numbers if decimalPlaces is null

    if (!isNumeric) return value; // Prevent invalid input

    // Limit decimal places if specified
    let formattedNumber = numericValue;
    if (decimalPlaces !== null) {
      const parts = numericValue.split(".");
      if (parts[1] && parts[1].length > decimalPlaces) {
        parts[1] = parts[1].substring(0, decimalPlaces); // Truncate to allowed decimal places
      }
      formattedNumber = parts.join(".");
    }

    // Format with commas (for the whole number part)
    const [whole, decimals] = formattedNumber.split(".");
    const wholeWithCommas = parseInt(whole || 0, 10).toLocaleString();

    let result =
      decimals !== undefined
        ? `${wholeWithCommas}.${decimals}`
        : wholeWithCommas;
    if (result === "0" && zeroValue !== null) {
      result = zeroValue;
    }
    return result;
  } catch (error) {
    console.log(error.message);
    return "";
  }
}

export function removeCommas(value) {
  try {
    if (value === null || value === undefined) return "";
    // Convert the value to a string in case it's a number or another type
    const stringValue = value.toString();
    return stringValue.replace(/,/g, "");
  } catch (error) {
    console.log(error.message);
    return "0";
  }
}

/**
 * Converts any value to a float if it's a string that can be parsed;
 * returns 0 if it's null/undefined or cannot be parsed.
 */
export function toNumberSafe(value) {
  if (value == null) return 0;
  if (typeof value === "string") {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  }
  if (typeof value === "number") {
    return value;
  }
  return 0; // fallback if it's something else
}
