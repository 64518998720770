import {
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  setDoc,
  query,
  where,
  writeBatch,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
  Timestamp,
  orderBy,
  limit,
} from "firebase/firestore";
import { firestore, functions } from "./FirebaseConfig";
import { httpsCallable } from "firebase/functions";
import { toFirestoreTimestamp } from "./utils/dateUtils";
import {
  fromFirestoreTimestamp,
  formatDateForDisplay,
} from "./utils/dateUtils";
import { toNumberSafe } from "./utils/numberUtils";

const getServerTimestamp = () => {
  return serverTimestamp();
};

const getEquipmentTypes = async () => {
  const equipmentTypesRef = collection(firestore, "equipmentTypes");
  const querySnapshot = await getDocs(equipmentTypesRef);
  const types = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return types;
};

const getCompanies = async (user) => {
  const companiesRef = collection(firestore, "companies");
  let companies = [];

  if (user?.role === "admin") {
    // Admins can see all companies
    const querySnapshot = await getDocs(companiesRef);
    companies = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } else {
    // Non-admins get the company matching their companyId
    if (user.companyId) {
      const companyDocRef = doc(companiesRef, user.companyId);
      const companyDoc = await getDoc(companyDocRef);
      if (companyDoc.exists()) {
        companies.push({
          id: companyDoc.id,
          ...companyDoc.data(),
        });
      } else {
        console.error(`Company with id ${user.companyId} does not exist`);
      }
    } else {
      console.error("User does not have a companyId");
    }
  }

  return companies;
};

const addCompany = async (companyData) => {
  const companiesRef = collection(firestore, "companies");
  const companyDataToAdd = {
    ...companyData,
    createdOn: serverTimestamp(),
  };
  return addDoc(companiesRef, companyDataToAdd);
};

const updateCompany = async (companyId, updatedData) => {
  const companyDoc = doc(firestore, "companies", companyId);
  return updateDoc(companyDoc, updatedData);
};

const deleteCompany = async (companyId) => {
  // Step 1: Get all users with the companyId
  const usersRef = collection(firestore, "users");
  const q = query(usersRef, where("companyId", "==", companyId));
  const querySnapshot = await getDocs(q);

  // Step 2: Initialize batch operations
  const batches = [];
  let batch = writeBatch(firestore);
  let operationCount = 0;

  querySnapshot.forEach((doc) => {
    batch.update(doc.ref, { companyId: "" });
    operationCount++;

    if (operationCount === 500) {
      batches.push(batch.commit());
      batch = writeBatch(firestore);
      operationCount = 0;
    }
  });

  // Commit any remaining operations
  if (operationCount > 0) {
    batches.push(batch.commit());
  }

  // Execute all batches
  await Promise.all(batches);

  // Step 3: Delete the company document
  const companyDoc = doc(firestore, "companies", companyId);
  return deleteDoc(companyDoc);
};

const getUsersByCompanyId = async (companyId) => {
  const usersRef = collection(firestore, "users");
  const q = query(usersRef, where("companyId", "==", companyId));
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

const getUserById = async (userId) => {
  const userRef = doc(firestore, "users", userId);
  const userDoc = await getDoc(userRef);
  if (userDoc.exists()) {
    console.log({ id: userDoc.id, ...userDoc.data() });
    return { id: userDoc.id, ...userDoc.data() };
  } else {
    console.log("No user found...");
    return null;
  }
};

const updateUser = async (userId, updatedData) => {
  const userRef = doc(firestore, "users", userId);
  return updateDoc(userRef, updatedData);
};

const getAllUsers = async () => {
  const usersRef = collection(firestore, "users");
  const querySnapshot = await getDocs(usersRef);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

const getAllCompanies = async () => {
  const companiesRef = collection(firestore, "companies");
  const querySnapshot = await getDocs(companiesRef);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

const addUser = async (userData) => {
  const usersRef = collection(firestore, "users");
  return addDoc(usersRef, userData);
};

const deleteUser = async (userId) => {
  const userRef = doc(firestore, "users", userId);
  return deleteDoc(userRef);
};

// Updated Equipment Methods
const getEquipment = async (user) => {
  const equipmentRef = collection(firestore, "equipment");
  let equipmentQuery;

  if (user.role === "admin") {
    // Admins have access to all equipment
    equipmentQuery = query(equipmentRef);
  } else if (user.role === "operator") {
    // Operators get equipment assigned to them
    equipmentQuery = query(
      equipmentRef,
      where("assignedOperators", "array-contains", user.uid)
    );
  } else if (user.role === "mechanic" || user.role === "owner") {
    // Mechanics and owners get equipment in their company
    equipmentQuery = query(
      equipmentRef,
      where("companyId", "==", user.companyId)
    );
  } else {
    // Default: No access
    return [];
  }

  const querySnapshot = await getDocs(equipmentQuery);
  const equipment = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return equipment;
};

const getAllEquipment = async () => {
  const equipmentRef = collection(firestore, "equipment");
  const querySnapshot = await getDocs(equipmentRef);
  const equipment = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return equipment;
};

const getEquipmentByOperatorId = async (operatorId) => {
  const equipmentRef = collection(firestore, "equipment");
  const q = query(
    equipmentRef,
    where("assignedOperators", "array-contains", operatorId)
  );
  const querySnapshot = await getDocs(q);

  const equipment = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return equipment;
};

const getEquipmentByCompanyId = async (companyId) => {
  const equipmentRef = collection(firestore, "equipment");
  const q = query(equipmentRef, where("companyId", "==", companyId));
  const querySnapshot = await getDocs(q);

  const equipment = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return equipment;
};

// Updated addEquipment method
const addEquipment = async (equipmentData) => {
  const equipmentRef = collection(firestore, "equipment");
  const dataToAdd = {
    ...equipmentData,
    updatedAt: serverTimestamp(),
    calcDateNextFilterChange: equipmentData.calcDateNextFilterChange
      ? toFirestoreTimestamp(equipmentData.calcDateNextFilterChange)
      : null,
    assignedOperators: Array.isArray(equipmentData.assignedOperators)
      ? equipmentData.assignedOperators
      : [],
  };
  try {
    // Add the equipment document
    const equipmentDocRef = await addDoc(equipmentRef, dataToAdd);

    // Add an initial service log
    const serviceLogData = {
      equipmentId: equipmentDocRef.id,
      filterChangeDate: serverTimestamp(), // Use current time for the initial service log
      engineHours: equipmentData.initialHours || 0, // Default to 0 if not provided
      currentMileage: equipmentData.initialMileage || 0, // Default to 0 if not provided
      createdBy: equipmentData.createdBy || null, // Track who created it
    };
    await addServiceLog(serviceLogData);

    return equipmentDocRef;
  } catch (error) {
    console.error("Error adding equipment and initial service log:", error);
    throw error;
  }
};

// Updated updateEquipment method
const updateEquipment = async (equipmentId, equipmentData) => {
  const equipmentRef = doc(firestore, "equipment", equipmentId);

  const dataToUpdate = {
    ...equipmentData,
    updatedAt: serverTimestamp(),
    assignedOperators: Array.isArray(equipmentData.assignedOperators)
      ? equipmentData.assignedOperators
      : [],
  };

  // Convert calcDateNextFilterChange using toFirestoreTimestamp
  if (equipmentData.calcDateNextFilterChange) {
    dataToUpdate.calcDateNextFilterChange = toFirestoreTimestamp(
      equipmentData.calcDateNextFilterChange
    );
  } else {
    dataToUpdate.calcDateNextFilterChange = null;
  }

  try {
    await updateDoc(equipmentRef, dataToUpdate);
  } catch (error) {
    console.error(`Error updating equipment ${equipmentId}:`, error);
    throw error;
  }
};

const deleteEquipment = async (equipmentId) => {
  const equipmentRef = doc(firestore, "equipment", equipmentId);
  return deleteDoc(equipmentRef);
};

const addEquipmentToCompany = async (companyId, equipmentId) => {
  const companyRef = doc(firestore, "companies", companyId);
  return updateDoc(companyRef, {
    equipment: arrayUnion(equipmentId),
  });
};

const removeEquipmentFromCompany = async (companyId, equipmentId) => {
  const companyRef = doc(firestore, "companies", companyId);
  return updateDoc(companyRef, {
    equipment: arrayRemove(equipmentId),
  });
};

// Equipment Assignment
const assignOperatorToEquipment = async (equipmentId, operatorId) => {
  const equipmentRef = doc(firestore, "equipment", equipmentId);
  return updateDoc(equipmentRef, {
    assignedOperators: arrayUnion(operatorId),
  });
};

const removeOperatorFromEquipment = async (equipmentId, operatorId) => {
  const equipmentRef = doc(firestore, "equipment", equipmentId);
  return updateDoc(equipmentRef, {
    assignedOperators: arrayRemove(operatorId),
  });
};

const searchUsersNotInCompany = async (searchTerm) => {
  const usersRef = collection(firestore, "users");
  const q = query(usersRef, where("companyId", "==", ""));
  const querySnapshot = await getDocs(q);

  let users = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  // Filter users matching the search term
  users = users.filter(
    (user) =>
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (user.displayName &&
        user.displayName.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return users;
};

const sendInvitationEmail = async (email, displayName) => {
  const sendInvitationEmailCallable = httpsCallable(
    functions,
    "sendInvitationEmail"
  );
  try {
    const result = await sendInvitationEmailCallable({ email, displayName });
    return result;
  } catch (error) {
    throw error;
  }
};

const checkIfEmailDenied = async (email) => {
  const checkIfEmailDeniedCallable = httpsCallable(
    functions,
    "checkIfEmailDenied"
  );
  try {
    const result = await checkIfEmailDeniedCallable({ email });
    return result.data;
  } catch (error) {
    throw error;
  }
};

async function getFuelLogsCount(equipmentId) {
  const q = query(
    collection(firestore, "fuel_logs"),
    where("equipmentId", "==", equipmentId)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.size;
}

async function getServiceLogsCount(equipmentId) {
  const q = query(
    collection(firestore, "service_logs"),
    where("equipmentId", "==", equipmentId)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.size;
}

async function addFuelLog(logData) {
  const formattedLogData = {
    ...logData,
    logDate: toFirestoreTimestamp(logData.logDate),
  };
  try {
    await addDoc(collection(firestore, "fuel_logs"), formattedLogData);
  } catch (error) {
    console.error("Error adding fuel log:", error);
    throw error;
  }
}

async function updateFuelLog(logId, logData) {
  const logRef = doc(firestore, "fuel_logs", logId);
  const formattedLogData = {
    ...logData,
    logDate: toFirestoreTimestamp(logData.logDate),
  };
  try {
    await updateDoc(logRef, formattedLogData);
  } catch (error) {
    console.error(`Error updating fuel log ${logId}:`, error);
    throw error;
  }
}

async function deleteFuelLog(logId) {
  const logRef = doc(firestore, "fuel_logs", logId);
  await deleteDoc(logRef);
}

async function getFuelLogs(equipmentId) {
  const q = query(
    collection(firestore, "fuel_logs"),
    where("equipmentId", "==", equipmentId),
    orderBy("logDate", "desc")
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

async function getFuelLogsByEquipmentIds(equipmentIds) {
  const fuelLogsRef = collection(firestore, "fuel_logs");
  const fuelLogs = [];

  const chunkSize = 10; // Firestore 'in' queries support up to 10 elements
  for (let i = 0; i < equipmentIds.length; i += chunkSize) {
    const chunk = equipmentIds.slice(i, i + chunkSize);
    const q = query(fuelLogsRef, where("equipmentId", "in", chunk));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      fuelLogs.push({ id: doc.id, ...doc.data() });
    });
  }

  return fuelLogs;
}

async function getFuelLogsByEquipmentIdsOverRange(
  equipmentIds,
  startDate,
  endDate
) {
  const fuelLogsRef = collection(firestore, "fuel_logs");
  const fuelLogs = [];

  const firestoreStartDate = Timestamp.fromDate(startDate);
  const firestoreEndDate = Timestamp.fromDate(endDate);

  for (const equipmentId of equipmentIds) {
    const q = query(
      fuelLogsRef,
      where("equipmentId", "==", equipmentId),
      where("logDate", ">=", firestoreStartDate),
      where("logDate", "<=", firestoreEndDate)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      fuelLogs.push({ id: doc.id, ...doc.data() });
    });
  }

  return fuelLogs;
}

// Function to add a new filter log
async function addServiceLog(logData) {
  const formattedLogData = {
    ...logData,
    filterChangeDate: toFirestoreTimestamp(logData.filterChangeDate),
  };
  try {
    await addDoc(collection(firestore, "service_logs"), formattedLogData);
  } catch (error) {
    console.error("Error adding filter log:", error);
    throw error;
  }
}

// Function to update an existing filter log
async function updateServiceLog(logId, logData) {
  const logRef = doc(firestore, "service_logs", logId);
  const formattedLogData = {
    ...logData,
    filterChangeDate: toFirestoreTimestamp(logData.filterChangeDate),
  };
  try {
    await updateDoc(logRef, formattedLogData);
  } catch (error) {
    console.error(`Error updating filter log ${logId}:`, error);
    throw error;
  }
}

// Function to delete a filter log
async function deleteServiceLog(logId) {
  const logRef = doc(firestore, "service_logs", logId);
  await deleteDoc(logRef);
}

// Function to get all filter logs for a specific equipment
async function getServiceLogs(equipmentId) {
  const q = query(
    collection(firestore, "service_logs"),
    where("equipmentId", "==", equipmentId),
    orderBy("filterChangeDate", "desc")
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

async function getServiceLogsByEquipmentIds(equipmentIds) {
  const fuelLogsRef = collection(firestore, "service_logs");
  const fuelLogs = [];

  const chunkSize = 10; // Firestore 'in' queries support up to 10 elements
  for (let i = 0; i < equipmentIds.length; i += chunkSize) {
    const chunk = equipmentIds.slice(i, i + chunkSize);
    const q = query(fuelLogsRef, where("equipmentId", "in", chunk));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      fuelLogs.push({ id: doc.id, ...doc.data() });
    });
  }

  return fuelLogs;
}

// Fetch a company by ID
async function getCompanyById(companyId) {
  const companyRef = doc(firestore, "companies", companyId);
  const companyDoc = await getDoc(companyRef);
  if (companyDoc.exists()) {
    return { id: companyDoc.id, ...companyDoc.data() };
  } else {
    console.error(`Company with id ${companyId} does not exist`);
    return null;
  }
}

// Find the latest service log for an equipment
async function getLatestServiceLog(equipmentId) {
  const q = query(
    collection(firestore, "service_logs"),
    where("equipmentId", "==", equipmentId),
    orderBy("filterChangeDate", "desc"),
    limit(1)
  );
  const snapshot = await getDocs(q);
  if (!snapshot.empty) {
    return { id: snapshot.docs[0].id, ...snapshot.docs[0].data() };
  }
  return null;
}

// Fetch the latest service logs for multiple equipment IDs
async function getLatestServiceLogs(equipmentIds) {
  const serviceLogsRef = collection(firestore, "service_logs");
  const latestLogs = {};

  const chunkSize = 10;
  for (let i = 0; i < equipmentIds.length; i += chunkSize) {
    const chunk = equipmentIds.slice(i, i + chunkSize);
    const q = query(serviceLogsRef, where("equipmentId", "in", chunk));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const equipmentId = data.equipmentId;
      const log = { id: doc.id, ...data };

      if (!latestLogs[equipmentId]) {
        latestLogs[equipmentId] = log;
      } else {
        if (
          log.filterChangeDate &&
          latestLogs[equipmentId].filterChangeDate &&
          log.filterChangeDate.toMillis() >
            latestLogs[equipmentId].filterChangeDate.toMillis()
        ) {
          latestLogs[equipmentId] = log;
        }
      }
    });
  }

  return latestLogs;
}

// Fetch fuel logs since a specific date for an equipment
async function getFuelLogsSinceDate(equipmentId, startDate) {
  const fuelLogsRef = collection(firestore, "fuel_logs");
  const q = query(
    fuelLogsRef,
    where("equipmentId", "==", equipmentId),
    where("logDate", ">=", Timestamp.fromDate(startDate))
  );
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

const getFilterTypes = async () => {
  const filterTypesRef = collection(firestore, "filterTypes");
  const querySnapshot = await getDocs(filterTypesRef);
  const types = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return types;
};

async function calculateAverageMPG(equipmentIds, startDate, endDate) {
  const fuelLogs = await getFuelLogsByEquipmentIdsOverRange(
    equipmentIds,
    startDate,
    endDate
  );

  const totalMiles = fuelLogs.reduce(
    (acc, log) => acc + (log.currentMileage || 0),
    0
  );
  const totalGallons = fuelLogs.reduce(
    (acc, log) => acc + (log.gallons || 0),
    0
  );

  // console.log(`totalMiles: ${totalMiles}`);
  // console.log(`totalGallons: ${totalGallons}`);

  if (totalGallons > 0) {
    const avgMPG = totalMiles / totalGallons;
    // console.log(`avgMPG: ${avgMPG}`);
    return avgMPG;
  } else {
    return null;
  }
}

// Get equipment by ID
async function getEquipmentById(equipmentId) {
  const equipmentRef = doc(firestore, "equipment", equipmentId);
  const equipmentDoc = await getDoc(equipmentRef);
  if (equipmentDoc.exists()) {
    return { id: equipmentDoc.id, ...equipmentDoc.data() };
  } else {
    return null;
  }
}

// Get the last fuel log before a given date
async function getLastFuelLogBeforeDate(equipmentId, logDate) {
  try {
    // logDate must be a JS Date
    const timestamp = Timestamp.fromDate(logDate);
    const fuelLogsRef = collection(firestore, "fuel_logs");
    const q = query(
      fuelLogsRef,
      where("equipmentId", "==", equipmentId),
      where("logDate", "<", timestamp),
      orderBy("logDate", "desc"),
      limit(1)
    );
    const snapshot = await getDocs(q);
    if (!snapshot.empty) {
      return { id: snapshot.docs[0].id, ...snapshot.docs[0].data() };
    }
  } catch (error) {
    console.log(error);
  }
  return null;
}

// Calculate and store calculatedMPG in the log data before adding/updating
async function prepareFuelLogData(logData, previousMileageOverride = null) {
  const { equipmentId, currentMileage, gallons, logDate } = logData;

  // Ensure logDate is a JS Date
  let jsLogDate = logDate;
  if (logDate && typeof logDate.toJSDate === "function") {
    jsLogDate = logDate.toJSDate();
  }

  let previousMileage;
  if (previousMileageOverride !== null) {
    // Use the provided previousMileage directly
    previousMileage = previousMileageOverride;
  } else {
    // Original logic: fetch from Firestore
    const lastLog = await getLastFuelLogBeforeDate(equipmentId, jsLogDate);
    if (lastLog) {
      previousMileage = lastLog.currentMileage;
    } else {
      const equipment = await getEquipmentById(equipmentId);
      previousMileage = equipment?.initialMileage || 0;
    }
  }

  const mileageDiff = currentMileage - previousMileage;
  let calculatedMPG = null;
  if (gallons && gallons > 0 && mileageDiff > 0) {
    calculatedMPG = mileageDiff / gallons;
  }

  return { ...logData, calculatedMPG };
}

// Get all fuel logs for a given equipment and month/year
async function getFuelLogsForMonth(equipmentId, year, month) {
  const startOfMonth = new Date(year, month, 1, 0, 0, 0);
  const endOfMonth = new Date(year, month + 1, 0, 23, 59, 59);

  const fuelLogsRef = collection(firestore, "fuel_logs");
  const q = query(
    fuelLogsRef,
    where("equipmentId", "==", equipmentId),
    where("logDate", ">=", Timestamp.fromDate(startOfMonth)),
    where("logDate", "<=", Timestamp.fromDate(endOfMonth))
  );

  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
}

// Recalculate and store monthly avg MPG
async function recalculateMonthlyAvgMPG(equipmentId, year, month) {
  const logs = await getFuelLogsForMonth(equipmentId, year, month);

  if (logs.length === 0) {
    // No logs for this month/year, delete the document if exists
    await deleteMonthlyAvgMPG(equipmentId, year, month);
    return;
  }

  const validLogs = logs.filter((log) => log.calculatedMPG != null);
  const avgMPG =
    validLogs.length > 0
      ? validLogs.reduce((acc, l) => acc + l.calculatedMPG, 0) /
        validLogs.length
      : null;

  await upsertMonthlyAvgMPG(equipmentId, year, month, avgMPG);
}

async function recalculateFuelLogsAfterInitialMileageChange(equipmentId) {
  // 1. Get the equipment to know its updated initialMileage
  const equipment = await getEquipmentById(equipmentId);
  if (!equipment) {
    throw new Error(`Equipment with ID ${equipmentId} not found.`);
  }

  // 2. Get all fuel logs in ascending order
  const fuelLogsRef = collection(firestore, "fuel_logs");
  const q = query(
    fuelLogsRef,
    where("equipmentId", "==", equipmentId),
    orderBy("logDate", "asc")
  );
  const snapshot = await getDocs(q);
  const allLogs = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  if (allLogs.length === 0) {
    // No logs to recalc
    return;
  }

  let previousMileage = equipment.initialMileage || 0;
  const updatedLogs = [];

  for (const log of allLogs) {
    // Use prepareFuelLogData with previousMileageOverride
    const updatedLogData = await prepareFuelLogData(log, previousMileage);
    const { calculatedMPG } = updatedLogData;

    // Update Firestore with the new calculatedMPG
    const logRef = doc(firestore, "fuel_logs", log.id);
    await updateDoc(logRef, { calculatedMPG });

    // Update previousMileage for the next iteration
    previousMileage = log.currentMileage;
    updatedLogs.push({ ...log, calculatedMPG });
  }

  // Recalculate monthly averages for affected months
  const monthYearSet = new Set();
  for (const log of updatedLogs) {
    const jsDate = log.logDate.toDate();
    const { year, month } = getYearMonthFromDate(jsDate);
    monthYearSet.add(`${year}-${month}`);
  }

  for (const ym of monthYearSet) {
    const [yearStr, monthStr] = ym.split("-");
    const year = parseInt(yearStr, 10);
    const month = parseInt(monthStr, 10);
    await recalculateMonthlyAvgMPG(equipmentId, year, month);
  }

  // Optionally recalculate filter life remaining if needed
  await recalculateFilterLifeRemaining(equipmentId);
}

// Upsert monthly avg MPG document
async function upsertMonthlyAvgMPG(equipmentId, year, month, avgMPG) {
  const avgRef = doc(
    firestore,
    "equipmentAvgMPG",
    `${equipmentId}_${year}_${month}`
  );
  const docData = {
    equipmentId,
    year,
    month,
    averageMPG: avgMPG,
    updatedAt: serverTimestamp(),
  };

  await updateDoc(avgRef, docData).catch(async (err) => {
    // If doc doesn't exist, create it
    if (err.code === "not-found") {
      await setDoc(avgRef, docData);
    } else {
      throw err;
    }
  });
}

// Delete monthly avg MPG doc if no logs remain
async function deleteMonthlyAvgMPG(equipmentId, year, month) {
  const avgRef = doc(
    firestore,
    "equipmentAvgMPG",
    `${equipmentId}_${year}_${month}`
  );
  await deleteDoc(avgRef).catch(() => {
    // If not exists, ignore
  });
}

// Extract month/year from a logDate (JS date)
function getYearMonthFromDate(date) {
  return { year: date.getFullYear(), month: date.getMonth() }; // month is zero-based
}

// Override addFuelLog with MPG calculation and monthly avg update
async function addFuelLogWithMPG(logData) {
  // Ensure logData.logDate is a JS Date
  let jsDate = logData.logDate;
  if (jsDate && jsDate.toJSDate) {
    jsDate = jsDate.toJSDate();
  }

  const preparedLogData = await prepareFuelLogData({
    ...logData,
    logDate: jsDate,
  });
  await addFuelLog(preparedLogData);

  const { year, month } = getYearMonthFromDate(jsDate);
  await recalculateMonthlyAvgMPG(preparedLogData.equipmentId, year, month);
}

// Override updateFuelLog with MPG calculation and monthly avg update
async function updateFuelLogWithMPG(logId, logData) {
  // Ensure logData.logDate is a JS Date
  let jsDate = logData.logDate;
  if (jsDate && jsDate.toJSDate) {
    jsDate = jsDate.toJSDate();
  }

  const preparedLogData = await prepareFuelLogData({
    ...logData,
    logDate: jsDate,
  });
  await updateFuelLog(logId, preparedLogData);

  const { year, month } = getYearMonthFromDate(jsDate);
  await recalculateMonthlyAvgMPG(preparedLogData.equipmentId, year, month);
}

// After deleting a fuel log, recalculate monthly avg if other logs remain
async function deleteFuelLogWithMPG(logId) {
  // Get log before delete to know equipmentId, month, year
  const logRef = doc(firestore, "fuel_logs", logId);
  const logDoc = await getDoc(logRef);
  if (!logDoc.exists()) {
    // Already deleted or doesn't exist
    return;
  }
  const logData = logDoc.data();

  await deleteFuelLog(logId);

  const date = logData.logDate.toDate();
  const { year, month } = getYearMonthFromDate(date);
  await recalculateMonthlyAvgMPG(logData.equipmentId, year, month);
}

function getLast30DayMPGForEquipment(equipment, allLogs) {
  // 1) Filter logs to just the ones for this equipment
  const eqLogs = allLogs.filter((log) => log.equipmentId === equipment.id);
  if (eqLogs.length === 0) {
    // fallback: eq.estMPG
    return equipment.estMPG || null;
  }

  // 2) Sort ascending by date
  const sorted = [...eqLogs].sort(
    (a, b) => a.logDate.toMillis() - b.logDate.toMillis()
  );

  // 3) Filter to last 30 days
  const now = new Date();
  const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
  const last30 = sorted.filter((l) => l.logDate.toDate() >= thirtyDaysAgo);

  // 4) fill-up approach
  if (last30.length < 2) {
    // Not enough logs to do fill-up; fallback
    return equipment.estMPG || null;
  }

  let totalMiles = 0;
  let totalGallons = 0;
  for (let i = 1; i < last30.length; i++) {
    const curr = last30[i];
    const prev = last30[i - 1];
    const distance = curr.currentMileage - prev.currentMileage;
    const gallonsThisFill = curr.gallons || 0;
    if (distance > 0 && gallonsThisFill > 0) {
      totalMiles += distance;
      totalGallons += gallonsThisFill;
    }
  }

  if (totalMiles > 0 && totalGallons > 0) {
    return totalMiles / totalGallons;
  } else {
    return equipment.estMPG || null; // fallback
  }
}

async function getFuelLogsForLast30Days(equipmentId) {
  const now = new Date();
  const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);

  const fuelLogsRef = collection(firestore, "fuel_logs");
  const q = query(
    fuelLogsRef,
    where("equipmentId", "==", equipmentId),
    where("logDate", ">=", Timestamp.fromDate(thirtyDaysAgo)),
    orderBy("logDate", "asc")
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
}

function calculateMPGFromLogs(logArray) {
  // If fewer than 2 records, we can't do a fill-up-to-fill-up calculation
  if (logArray.length < 2) {
    return { totalMiles: 0, totalGallonsUsed: 0 };
  }

  let totalMiles = 0;
  let totalGallonsUsed = 0;

  // Skip the first log, because we don't know how much fuel was in the tank before it
  for (let i = 1; i < logArray.length; i++) {
    const current = logArray[i];
    const previous = logArray[i - 1];

    const currentOdo = toNumberSafe(current.currentMileage);
    const previousOdo = toNumberSafe(previous.currentMileage);
    const gallonsThisFill = toNumberSafe(current.gallons);

    // Only calculate if we have valid odometer readings and positive miles
    if (currentOdo > previousOdo && gallonsThisFill > 0) {
      totalMiles += currentOdo - previousOdo;
      totalGallonsUsed += gallonsThisFill;
    }
  }

  return { totalMiles, totalGallonsUsed };
}

// Calculate sums and averages from logs
function calculateFuelStats(eq, logs) {
  // If no logs, return defaults
  if (!logs || logs.length === 0) {
    return {
      totalGallons: 0,
      totalDefGallons: 0,
      avgMPGAllTime: eq.estMPG || "",
      avgMPG30Days: "",
      latestFuelLogDate: "",
    };
  }

  // 1) Sort logs by date ascending
  const sortedLogs = [...logs].sort(
    (a, b) => a.logDate.toMillis() - b.logDate.toMillis()
  );

  // 2) Calculate All-Time MPG
  const { totalMiles, totalGallonsUsed } = calculateMPGFromLogs(sortedLogs);
  let avgMPGAllTime = "";
  if (totalMiles > 0 && totalGallonsUsed > 0) {
    avgMPGAllTime = totalMiles / totalGallonsUsed;
  } else {
    // Fall back to the equipment's estimated MPG if calculation isn't possible
    // Also parse eq.estMPG, in case it's a string
    const estMPG = toNumberSafe(eq.estMPG);
    avgMPGAllTime = estMPG > 0 ? estMPG : "";
  }

  // 3) Calculate Last 30 Days MPG
  const now = new Date();
  const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
  // Filter logs to only the last 30 days
  const last30DaysLogs = sortedLogs.filter(
    (l) => l.logDate.toDate() >= thirtyDaysAgo
  );

  let avgMPG30Days = "";
  if (last30DaysLogs.length > 1) {
    const { totalMiles: last30Miles, totalGallonsUsed: last30Gallons } =
      calculateMPGFromLogs(last30DaysLogs);

    if (last30Miles > 0 && last30Gallons > 0) {
      avgMPG30Days = last30Miles / last30Gallons;
    }
  }

  // 4) Total fuel + DEF gallons (convert them to numbers before summation)
  const totalGallons = sortedLogs.reduce(
    (sum, l) => sum + toNumberSafe(l.gallons),
    0
  );
  const totalDefGallons = sortedLogs.reduce(
    (sum, l) => sum + toNumberSafe(l.defGallons),
    0
  );

  // 5) Latest Fuel Log Date
  // Sort descending by date to get the newest one:
  const latestFuelLog = [...sortedLogs].sort(
    (a, b) => b.logDate.toMillis() - a.logDate.toMillis()
  )[0];
  const latestFuelLogDate = latestFuelLog
    ? formatDateForDisplay(fromFirestoreTimestamp(latestFuelLog.logDate))
    : "";

  return {
    totalGallons,
    totalDefGallons,
    avgMPGAllTime,
    avgMPG30Days,
    latestFuelLogDate,
  };
}

// Create or update equipmentMetrics record
async function upsertEquipmentMetrics(equipmentId, data) {
  const metricsRef = collection(firestore, "equipmentMetrics");
  const q = query(metricsRef, where("equipmentId", "==", equipmentId));
  const snapshot = await getDocs(q);

  if (snapshot.empty) {
    // Create new metrics record
    await addDoc(metricsRef, data);
  } else {
    // Update the existing record (assume only one per equipment)
    const docRef = snapshot.docs[0].ref;
    await updateDoc(docRef, data);
  }
}

// Fetch equipmentMetrics by equipmentId
async function getEquipmentMetricsByEquipmentId(equipmentId) {
  const metricsRef = collection(firestore, "equipmentMetrics");
  const q = query(metricsRef, where("equipmentId", "==", equipmentId));
  const snapshot = await getDocs(q);
  if (!snapshot.empty) {
    const docData = snapshot.docs[0].data();
    return { id: snapshot.docs[0].id, ...docData };
  }
  return null;
}

async function getEquipmentMetricsByEquipmentIds(equipmentIds) {
  const metricsRef = collection(firestore, "equipmentMetrics");
  const metrics = [];

  // Firestore 'in' queries support up to 10 elements
  const chunkSize = 10;
  for (let i = 0; i < equipmentIds.length; i += chunkSize) {
    const chunk = equipmentIds.slice(i, i + chunkSize);
    const q = query(metricsRef, where("equipmentId", "in", chunk));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      metrics.push({ id: doc.id, ...doc.data() });
    });
  }

  return metrics;
}

// Determine if filterType is hours or miles and set fields accordingly
function getFilterLifeFields(filterTypeData) {
  // filterTypeData might look like {id: ..., name:..., hours:..., miles:...}
  // If it has hours defined, use hours fields. If it has miles, use miles fields.
  const fields = {
    filterLifeHoursMax: null,
    filterLifeHoursRemaining: null,
    filterLifeMileageMax: null,
    filterLifeMileageRemaining: null,
  };

  if (filterTypeData) {
    if (filterTypeData.hours) {
      fields.filterLifeHoursMax = filterTypeData.hours;
      fields.filterLifeHoursRemaining = filterTypeData.hours;
    } else if (filterTypeData.miles) {
      fields.filterLifeMileageMax = filterTypeData.miles;
      fields.filterLifeMileageRemaining = filterTypeData.miles;
    }
  }

  return fields;
}

// Find the latest fuel log for an equipment
async function getLatestFuelLog(equipmentId) {
  const q = query(
    collection(firestore, "fuel_logs"),
    where("equipmentId", "==", equipmentId),
    orderBy("logDate", "desc"),
    limit(1)
  );
  const snapshot = await getDocs(q);
  if (!snapshot.empty) {
    return { id: snapshot.docs[0].id, ...snapshot.docs[0].data() };
  }
  return null;
}

// Recalculate remaining filter life based on the latest fuel log
async function recalculateFilterLifeRemaining(equipmentId) {
  // Step 1: Get the latest metrics for the equipment
  const metrics = await getEquipmentMetricsByEquipmentId(equipmentId);
  if (!metrics) return; // No metrics exist, nothing to recalculate

  // Step 2: Get the latest service log for the equipment
  const latestServiceLog = await getLatestServiceLog(equipmentId);
  if (!latestServiceLog) return; // No service log exists, nothing to recalculate

  // Step 3: Get the latest fuel log after the latest service log's filterChangeDate
  const filterChangeDate = latestServiceLog.filterChangeDate.toDate();
  const fuelLogsRef = collection(firestore, "fuel_logs");
  const fuelLogQuery = query(
    fuelLogsRef,
    where("equipmentId", "==", equipmentId),
    where("logDate", ">", Timestamp.fromDate(filterChangeDate)),
    orderBy("logDate", "desc"),
    limit(1)
  );
  const fuelLogSnapshot = await getDocs(fuelLogQuery);
  if (fuelLogSnapshot.empty) return; // No relevant fuel logs exist, nothing to recalculate

  const latestFuelLog = {
    id: fuelLogSnapshot.docs[0].id,
    ...fuelLogSnapshot.docs[0].data(),
  };

  // Step 4: Prepare the update data for filter life
  let updateData = {};

  // Filter life calculation based on engine hours
  if (
    metrics.filterLifeHoursMax &&
    latestFuelLog.engineHours !== undefined &&
    latestFuelLog.engineHours !== null
  ) {
    if (
      toNumberSafe(latestFuelLog.engineHours) <=
      toNumberSafe(metrics.engineHours)
    )
      return; // Fuel log engine hours must be greater than metrics
    const hoursUsed =
      toNumberSafe(latestFuelLog.engineHours) -
      toNumberSafe(metrics.engineHours);
    updateData.filterLifeHoursRemaining =
      toNumberSafe(metrics.filterLifeHoursMax) - hoursUsed;
  }

  // Filter life calculation based on mileage
  if (
    metrics.filterLifeMileageMax &&
    latestFuelLog.currentMileage !== undefined &&
    latestFuelLog.currentMileage !== null
  ) {
    if (
      toNumberSafe(latestFuelLog.currentMileage) <=
      toNumberSafe(metrics.mileage)
    )
      return; // Fuel log mileage must be greater than metrics
    const milesUsed =
      toNumberSafe(latestFuelLog.currentMileage) -
      toNumberSafe(metrics.mileage);
    updateData.filterLifeMileageRemaining =
      toNumberSafe(metrics.filterLifeMileageMax) - milesUsed;
  }

  // Step 5: Update the metrics document
  const metricsRef = collection(firestore, "equipmentMetrics");
  const metricsQuery = query(
    metricsRef,
    where("equipmentId", "==", equipmentId)
  );
  const metricsSnapshot = await getDocs(metricsQuery);
  if (!metricsSnapshot.empty) {
    const metricsDocRef = metricsSnapshot.docs[0].ref;
    await updateDoc(metricsDocRef, updateData);
  }
}

async function updateMetricWithLatestServiceLogHoursAndMileage(equipmentId) {
  try {
    // Step 1: Get the latest service log for the equipment
    const latestServiceLog = await getLatestServiceLog(equipmentId);
    if (!latestServiceLog) {
      console.error(`No service log found for equipmentId: ${equipmentId}`);
      return; // Exit if no service log exists
    }

    // Step 2: Get the equipment metrics record for the equipment
    const metrics = await getEquipmentMetricsByEquipmentId(equipmentId);
    if (!metrics) {
      console.error(
        `No equipment metrics found for equipmentId: ${equipmentId}`
      );
      return; // Exit if no metrics exist
    }

    // Step 3: Update the engineHours and mileage values in the metrics record
    const updateData = {
      engineHours: toNumberSafe(latestServiceLog.engineHours),
      mileage: toNumberSafe(latestServiceLog.currentMileage),
      updatedAt: serverTimestamp(), // Add a timestamp to track the update
    };

    const metricsRef = collection(firestore, "equipmentMetrics");
    const metricsQuery = query(
      metricsRef,
      where("equipmentId", "==", equipmentId)
    );
    const metricsSnapshot = await getDocs(metricsQuery);

    if (!metricsSnapshot.empty) {
      const metricsDocRef = metricsSnapshot.docs[0].ref;
      await updateDoc(metricsDocRef, updateData);
      console.log(`Metrics updated for equipmentId: ${equipmentId}`);
    } else {
      console.error(
        `Metrics document not found for equipmentId: ${equipmentId}`
      );
    }
  } catch (error) {
    console.error(
      `Error updating metrics for equipmentId: ${equipmentId}`,
      error
    );
  }
}

function calculateFillUpMPGForLogSet(logs) {
  if (logs.length < 2) return 0;
  let totalMiles = 0;
  let totalGallons = 0;
  for (let i = 1; i < logs.length; i++) {
    const curr = logs[i];
    const prev = logs[i - 1];
    const distance =
      toNumberSafe(curr.currentMileage) - toNumberSafe(prev.currentMileage);
    const gallons = toNumberSafe(curr.gallons) || 0;
    if (distance > 0 && gallons > 0) {
      totalMiles += distance;
      totalGallons += gallons;
    }
  }
  return totalGallons > 0 ? totalMiles / totalGallons : 0;
}

async function getEquipmentAvgMPGForEquipment(equipmentId) {
  const eqAvgMPG = [];
  const eqAvgMPGRef = collection(firestore, "equipmentAvgMPG");
  const qy = query(eqAvgMPGRef, where("equipmentId", "==", equipmentId));
  const mpgSnapshot = await getDocs(qy);
  mpgSnapshot.forEach((doc) => eqAvgMPG.push({ id: doc.id, ...doc.data() }));
  return eqAvgMPG;
}

/**
 * Gather all data needed to export a single Equipment item.
 * Returns an object { eqDoc, filterLogs, fuelLogs, metrics, eqAvgMPG }.
 */
async function getExportDataForEquipment(equipmentId) {
  // 1) The single 'equipment' doc
  const eqDoc = await getEquipmentById(equipmentId);

  // 2) filter_logs => your "service_logs"
  const filterLogs = await getServiceLogs(equipmentId);

  // 3) fuel_logs
  const fuelLogs = await getFuelLogs(equipmentId);

  // 4) equipmentMetrics => usually 0 or 1 doc
  const metrics = await getEquipmentMetricsByEquipmentId(equipmentId);

  // 5) equipmentAvgMPG => possibly multiple docs if you store monthly data
  const eqAvgMPG = await getEquipmentAvgMPGForEquipment(equipmentId);

  return {
    eqDoc, // the single equipment doc
    filterLogs, // array of service logs
    fuelLogs, // array of fuel logs
    metrics, // single metrics doc or null
    eqAvgMPG, // array of eqAvgMPG docs
  };
}

const firebaseService = {
  getEquipmentTypes,
  getFilterTypes,
  getServerTimestamp,
  getCompanies,
  addCompany,
  updateCompany,
  deleteCompany,
  getUsersByCompanyId,
  getUserById,
  updateUser,
  getAllUsers,
  getAllCompanies,
  addUser,
  deleteUser,
  getEquipment,
  getAllEquipment,
  getEquipmentByOperatorId,
  getEquipmentByCompanyId,
  addEquipment,
  updateEquipment,
  deleteEquipment,
  addEquipmentToCompany,
  removeEquipmentFromCompany,
  assignOperatorToEquipment,
  removeOperatorFromEquipment,
  searchUsersNotInCompany,
  sendInvitationEmail,
  checkIfEmailDenied,
  getFuelLogsCount,
  getServiceLogsCount,
  addFuelLog,
  updateFuelLog,
  deleteFuelLog,
  getFuelLogs,
  getFuelLogsByEquipmentIds,
  getFuelLogsByEquipmentIdsOverRange,
  addServiceLog,
  updateServiceLog,
  deleteServiceLog,
  getServiceLogs,
  getServiceLogsByEquipmentIds,
  getCompanyById,
  getLatestServiceLog,
  getLatestServiceLogs,
  getFuelLogsSinceDate,
  calculateAverageMPG,
  addFuelLogWithMPG,
  updateFuelLogWithMPG,
  deleteFuelLogWithMPG,
  getLast30DayMPGForEquipment,
  getFuelLogsForLast30Days,
  calculateFuelStats,
  calculateMPGFromLogs,
  upsertEquipmentMetrics,
  getEquipmentMetricsByEquipmentId,
  getEquipmentMetricsByEquipmentIds,
  getFilterLifeFields,
  getLatestFuelLog,
  recalculateFilterLifeRemaining,
  recalculateFuelLogsAfterInitialMileageChange,
  updateMetricWithLatestServiceLogHoursAndMileage,
  calculateFillUpMPGForLogSet,
  getEquipmentAvgMPGForEquipment,
  getExportDataForEquipment,
};

export default firebaseService;
